/** ------------------------------------------------------------------------------------------------------------------
 * Table
 * A component that renders a table view with advanced functionalities such as sorting, pagination, row expansion, etc.
 *
 * @examples
 *
 *  ```jsx
 *    <TableBody
 *      columns={columns}
 *      items={items}
 *      cellRenderer={<cell_render_function>}
 *      cellDetailsRenderer={<cell_details_renderer_function>}
 *      showContextualMenu={true}
 *      sortFunctions={<sort_functions_factory>}
 *      onRowClicked={(event) => console.log("Row clicked")}
 *      onBulkContextualAction={(event) => console.log("On bulk action")}
 *    />
 * ```
 *
 *  @component Table
 *  @import Table
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import getId from "../utils/get-id";
import Pagination from "../pagination";
import Checkbox from "../checkbox";
import { TableHeader } from "./table-header";
import { TableBody } from "./table-body";

import { format } from "../../../utils/strings";

import "./table-view.scss";


export default class Table extends Component {

    constructor(props) {
        super(props);

        const totalItems = props.items.length;
        const numberOfPages = Math.ceil(props.items.length / props.itemsPerPage);
        const extraColumns = Number(!!props.showSelectors) + Number(!!props.showContextualMenu);

        const initialSortColumn = this.props.columns.length ?
            this.props.columns.find((column) => column.sortable) : null;
        const sortProperty = initialSortColumn ? initialSortColumn.dataField : null;
        const sortOrder = props.columns.reduce((result, current) => {
            result[current.dataField] = "asc";
            return result;
        }, {});

        this.state = {
            totalItems,
            numberOfPages,
            pageOffset: 0,
            extraColumns,
            currentPage: 1,
            selectedPage: 1,
            allItemsSelected: false,
            bulkContextualSelectorActive: false,
            sortProperty,
            sortOrder,
            start: 1,
            end: props.itemsPerPage,
            selectedItems: [],
            contextualIcon: `icon-${props.contextualIcon}`,
            activeContextualIcon: `icon-${props.activeContextualIcon}`,
            sortedViews: {}
        };
    }

    _invertSortOrder(column) {
        const inverses = {
            "asc": "desc",
            "desc": "asc"
        };

        const { sortOrder } = this.state;
        const currentOrderForColumn = sortOrder[column];
        sortOrder[column] = inverses[currentOrderForColumn];

        this.setState({
            sortOrder
        });
    }

    _onBulkSelectorChange = (id, checked) => {
        this._getCurrentPageItems().forEach((item) => {
            item.selected = checked;
        });

        this.setState({
            allItemsSelected: checked
        });

        this._updateSelectedItemStatus();
    };

    _sortItemsBy = (column, property, invertOrder = true) => {

        if (!column.sortable) {
            return;
        }

        const { sortFunctions } = this.props;
        const { sortedViews, sortOrder, currentPage } = this.state;

        if (invertOrder) {
            this._invertSortOrder(property);
        }

        const byProperty = sortFunctions(property, sortOrder[property]);
        const currentPageItems = this._getCurrentPageItems();
        currentPageItems.sort(byProperty);
        sortedViews[`page-${currentPage}`] = currentPageItems;

        this.setState({
            sortProperty: property,
            sortedViews
        });
    };

    _onBulkContextualAction = (event) => {
        this.setState({
            bulkContextualSelectorActive: !this.state.bulkContextualSelectorActive
        });

        if (this.props.onBulkContextualAction) {
            this.props.onBulkContextualAction(event, this._getCurrentPageItems());
        }
    };

    _onItemChecked = (itemId, checked) => {
        const item = this.props.items.find((item) => `${item.id}` === `${itemId.split("-")[1]}`);

        if (item) {
            item.selected = checked;
            this._updateSelectedItemStatus();
        }
    };

    _updateSelectedItemStatus() {
        const selectedItems = this.props.items.filter((item) => item.selected);
        const currentPageItems = this._getCurrentPageItems();
        const allItemsSelected = currentPageItems.every((item) => item.selected);

        this.setState({
            selectedItems,
            allItemsSelected
        });

        if (this.props.onItemsSelected) {
            this.props.onItemsSelected(selectedItems);
        }
    }

    _onRowClicked = (event) => {
        if (this.props.onRowClicked) {
            this.props.onRowClicked(event);
        }
    };

    _onPaginationUpdated = ({ start, end, currentPage, pageOffset }) => {
        this.setState({
            start,
            end,
            currentPage,
            pageOffset
        });
    }

    _getCurrentPageItems() {
        const {start, end, totalItems} = this.state;
        const from = (start > totalItems) ? totalItems : start;
        const to = (end >= totalItems) ? totalItems : end;

        return this.props.items.slice(from - 1, to);
    }

    componentWillMount() {
        const { sortProperty } = this.state;
        const column = this.props.columns.find((column) => column.sortable) || this.props.columns[0];

        this._sortItemsBy(column, sortProperty, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items.length !== this.props.items.length) {
            const { items, itemsPerPage } = this.props;
            const totalItems = items.length;
            const numberOfPages = Math.ceil(items.length / itemsPerPage);

            this.setState({
                totalItems,
                numberOfPages,
                start: 1,
                end: itemsPerPage,
                pageOffset: 0,
                currentPage: 1,
                sortedViews: {}
            });
        }
    }

    render() {
        const {
            className,
            columns,
            items,
            itemsPerPage,
            cellRenderer,
            cellDetailsRenderer,
            showSelectors,
            showFavorite,
            showContextualMenu,
            showPagination,
            paginationTemplate,
            paginationOfResultsTemplate,
            caption
        } = this.props;

        const {
            totalItems,
            extraColumns,
            currentPage,
            sortProperty,
            contextualIcon,
            activeContextualIcon,
            start,
            end,
            allItemsSelected,
            bulkContextualSelectorActive,
            sortOrder,
            sortedViews
        } = this.state;

        const currentPageItems = sortedViews[`page-${currentPage}`] || this._getCurrentPageItems();
        const shouldAddPadding = currentPageItems.length < itemsPerPage;
        const extraRows = [];

        if (shouldAddPadding) {
            for (let i = 0; i < itemsPerPage - currentPageItems.length; i++) {
                extraRows.push(
                    <tr className="row-padding" key={`extra-row-${i}`} role="row">
                        <td colSpan={columns.length + extraColumns}>
                            <span>{" "}</span>
                        </td>
                    </tr>
                );
            }
        }

        return (
            <React.Fragment>
                <div className={classNames("row header-wrapper", className)}>
                    <header className="column medium-11 small-4 header">
                        <span className="results">
                            <span>{totalItems}</span> results
                        </span>
                        {(start && end) &&
                            <em>
                                {format(paginationTemplate, {
                                    start,
                                    end: (end < totalItems) ? end : totalItems,
                                    total: totalItems
                                })}
                            </em>
                        }
                    </header>
                </div>
                <table className="data-table item-table" role="grid" aria-describedby="itemListCaption">
                    <caption>{caption}</caption>
                    <thead>
                        <tr role="row">
                            <th className="selector">
                                <Checkbox
                                    id={`bulk-selector-${getId()}`}
                                    checked={allItemsSelected}
                                    onChange={this._onBulkSelectorChange}
                                />
                            </th>
                            {columns.map((column, index) => (
                                <TableHeader
                                    key={index}
                                    property={column.dataField}
                                    sortable={column.sortable}
                                    isActiveSortColumn={column.dataField === sortProperty}
                                    onClick={() => this._sortItemsBy(column, column.dataField)}
                                    columnName={column.headerName}
                                    isAscendingOrder={sortOrder[column.dataField] === "asc"}
                                />
                            ))}
                            {showContextualMenu &&
                                <th className="actions">
                                    <button
                                        className={classNames("icon", contextualIcon, {
                                            [activeContextualIcon]: bulkContextualSelectorActive
                                        })}
                                        data-action="bulkContextualAction"
                                        onClick={this._onBulkContextualAction}
                                    />
                                </th>
                            }
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <td colSpan={columns.length + extraColumns}>
                                {showPagination && items.length &&
                                    <Pagination
                                        totalItems={totalItems}
                                        itemsPerPage={itemsPerPage}
                                        paginationTemplate={paginationOfResultsTemplate}
                                        onPaginationUpdated={this._onPaginationUpdated}
                                    />
                                }
                            </td>
                        </tr>
                    </tfoot>
                    <tbody>
                        {currentPageItems.map((item, index) => (
                            <TableBody
                                key={index}
                                item={item}
                                columns={columns}
                                extraColumns={extraColumns}
                                showSelectors={showSelectors}
                                showFavorite={showFavorite}
                                showContextualMenu={showContextualMenu}
                                cellRenderer={cellRenderer}
                                cellDetailsRenderer={cellDetailsRenderer}
                                onClick={this._onRowClicked}
                                onSelectorChecked={this._onItemChecked}
                                contextualIcon={contextualIcon}
                                activeContextualIcon={activeContextualIcon}
                            />
                        ))}
                        {shouldAddPadding && extraRows}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

const cellRenderer = (item, columnField) => <span data-type={columnField}>{item[columnField]}</span>;
const cellDetailsRenderer = (item) => <p className="item-details">{item.id}</p>;
const defaultSortFunctions = (key, direction = "asc") => {
    return (a, b) => {
        const result = `${a[key]}`.localeCompare(`${b[key]}`);
        return direction === "asc" ? result : -result;
    };
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    cellRenderer: PropTypes.func.isRequired,
    cellDetailsRenderer: PropTypes.func,
    className: PropTypes.string,
    caption: PropTypes.string,
    showSelectors: PropTypes.bool,
    showPagination: PropTypes.bool,
    paginationTemplate: PropTypes.string,
    paginationOfResultsTemplate: PropTypes.string,
    showFavorite: PropTypes.bool,
    showContextualMenu: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    contextualIcon: PropTypes.string,
    activeContextualIcon: PropTypes.string,
    onItemsSelected: PropTypes.func,
    onRowClicked: PropTypes.func,
    onBulkContextualAction: PropTypes.func,
    dateFormat: PropTypes.string,
    sortFunctions: PropTypes.func
};

Table.defaultProps = {
    columns: [],
    items: [],
    className: "",
    cellRenderer,
    cellDetailsRenderer,
    showSelectors: true,
    showContextualMenu: true,
    showFavorite: true,
    showPagination: true,
    itemsPerPage: 10,
    contextualIcon: "chevron-down",
    activeContextualIcon: "chevron-up",
    dateFormat: "yyyy-MM-dd HH:mm:ss",
    paginationTemplate: "{start}-{end} of {total}",
    paginationOfResultsTemplate: " of {total}",
    sortFunctions: defaultSortFunctions,
    onItemsSelected: () => {},
    onBulkContextualAction: () => {},
    onRowClicked: () => {}
};
