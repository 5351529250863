export default [
    {"id": "event-1", "name": "Economics 101", "summary": "Introduction to Microeconomics",
        "type": "color-orange", "categoryName": "Live", "status": "in-progress"},
    {"id": "event-2", "name": "Strategy and Innovation",
        "summary": "Strategies for innovating in a competitive environment",
        "type": "color-yellow", "categoryName": "Live", "status": "in-progress"},
    {"id": "event-3", "name": "Digital Marketing",
        "summary": "Foundations of digital marketing and framework for success",
        "type": "color-red", "categoryName": "Recorded", "status": "complete"},
    {"id": "event-4", "name": "Globalization",
        "summary": "How to navigate the global economy", "type": "color-green",
        "categoryName": "Live", "status": "in-progress"},
    {"id": "event-5", "name": "Social Media Marketing",
        "summary": "What's your Social Media strategy? How do you track success?",
        "type": "color-blue", "categoryName": "Live", "status": "in-progress"},
    {"id": "event-6", "name": "SWOT Analysis",
        "summary": "How to analyze the strengths, weaknesses, opportunities, and threats",
        "type": "color-purple", "categoryName": "Recorded", "status": "complete"},
    {"id": "event-7", "name": "Case: Finland and Nokia",
        "summary": "Case study - Finland and Nokia: Creating the world's most competitive economy",
        "type": "color-grey", "categoryName": "Live", "status": "in-progress"}
];
