import React, { PureComponent } from "react";
import { TabContent } from "../shared/tabbed-view/tab-content";
import DashboardModule from "../shared/dashboard-module";
import Selector from "../shared/selector";
import Slider from "../shared/slider";
import ModalDialog from "../shared/modal";
import ModalSheet from "../shared/modal/modal-sheet";
import NotificationPanel from "../shared/modal/notification-modal";
import ListView from "../shared/list-view";
import Button from "../../components/shared/button";
import LanguageSelector from "../../components/shared/language-selector";
import DurationSelector from "../../components/shared/duration-selector";
import ContextualDialog from "../../components/shared/modal/contextual-dialog";

import HTML from "@akwaba/html";

import events from "../../store/data/events";
import languages from "../../store/data/languages";
import * as DateTimeFormatter from "../../utils/dates";


/* eslint-disable no-console */
export class DashboardView extends PureComponent {

    constructor(props) {
        super(props);

        this.contextualActionContainer = React.createRef();
        this.contextualActionContainer2 = React.createRef();

        this.state = {
            showingModalDialog: false,
            showingModalSheet: false
        };
    }

    _showModalDialog = () => {
        this.setState({
            showingModalDialog: true
        });
    };

    _showModalSheet = () => {
        this.setState({
            showingModalSheet: true
        });
    };

    _showNotificationPanel = () => {
        this.setState({
            showingNotificationPanel: true
        });
    };

    _onModalClose = () => {
        this.setState({
            showingModalDialog: false,
            showingModalSheet: false,
            showingNotificationPanel: false
        });
    };

    _onOK = () => {
        this.setState({
            showingModalSheet: false
        });
    };

    _onListItemClicked = (event) => {
        console.log("Item clicked: ", event.target);
    };

    _itemRenderer = (item, index) => (
        <li key={index} className="form-selector-singlecolumn column medium-4 small-6">
            <input className="form-choice form-choice-selector rounded" id={item.id} name="schooltype" type="radio" />
            <div className="form-choice-selector-label">
                <label className="form-label form-example-desktop" htmlFor={item.id}>
                    {item.categoryName}
                </label>
                <label className="form-label form-example-mobile" htmlFor={item.id}>
                    {item.name}
                </label>
            </div>
        </li>
    );

    _showContextualDialog = () => {
        const onModalHide = () => {
            this.contextualDialog = null;
        };

        const contentContainer = HTML.createElement("div", { className: "detail-container" });
        HTML.setContent(contentContainer, `
            <h3>Callout</h3>
            <p>&nbsp;</p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                sed do eiusmod tempor et incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation.
            </p>
        `);

        this.contextuaDialog = new ContextualDialog({
            content: contentContainer,
            relativeTo: this.contextualActionContainer.current,
            size: "normal",
            offsetLeft: 20,
            offsetTop: 10,
            modalDidHide: onModalHide
        });
    };

    _showOverflowContextualDialog = () => {
        if (this.contextualDialog2) {
            this.contextualDialog2 = null;
        }

        const contentContainer = HTML.createElement("div", { className: "detail-container" });
        HTML.setContent(contentContainer, `
            <h3>Grid Systems</h3>
            <p>&nbsp;</p>
            <p>
                Columns don't have gutters or any other spacing in between them. 
                The developer is responsible for adding margin|padding to the elements inside 
                of the column to further control the spacing. This also allows grids 
                to be fully nestable.
            </p>
        `);

        this.contextuaDialog2 = new ContextualDialog({
            id: "lecture-details-panel2",
            content: contentContainer,
            relativeTo: this.contextualActionContainer2.current,
            size: "normal",
            offsetLeft: 20,
            offsetTop: -12
        });
    }

    render() {
        const {
            showingModalDialog,
            showingModalSheet,
            showingNotificationPanel
        } = this.state;

        return (
            <TabContent key="dashboard" title="Dashboard View" className="tab-content">
                <div className="component-wrapper dashboard-view">
                    <div className="module-section language-view">
                        <LanguageSelector
                            languages={languages}
                        />
                    </div>
                    <div className="module-section">
                        <DashboardModule
                            className="home-module"
                            header={
                                <React.Fragment>
                                    <h3 className="column medium-11">Requisitions</h3>
                                    <div className="filters">
                                        <Selector
                                            label="Show"
                                            options={[
                                                {id: "latest", title: "Latest Activity" },
                                                {id: "week", title: "Weekly Activities" },
                                                {id: "month", title: "Monthly Activities" },
                                                {id: "all", title: "All Activities" }
                                            ]}
                                            onChange={(event) => console.log("onChange: ", event.target.value)}
                                        />
                                    </div>
                                    <div className="column medium-1 medium-hide small-show toggle-icon">
                                        <button className="icon icon-chevrondown"></button>
                                    </div>
                                </React.Fragment>
                            }
                            content={
                                <React.Fragment>
                                    <div className="form-cell import-type">
                                        <Selector
                                            label="Import File"
                                            className="file-import"
                                            options={{
                                                "Types": [
                                                    {id: "word", title: "Word Resume" },
                                                    {id: "pdf", title: "PDF Resume" }
                                                ],
                                                "Last Modified": [
                                                    {id: "recent", title: "Most recent" },
                                                    {id: "this-week", title: "This week" }
                                                ]
                                            }}
                                            onChange={(event) => console.log("onChange: ", event.target.value)}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                            actions={
                                <React.Fragment>
                                    <a href="javascript:void(0)">View more
                                        <span className="icon icon-after icon-chevronrightcircle"/>
                                    </a>
                                </React.Fragment>
                            }
                        />
                    </div>
                    <div className="module-section" style={{position: "relative", zIndex: 10}}>
                        <DashboardModule
                            className="events-module"
                            header={
                                <React.Fragment>
                                    <h3 className="column medium-11">Schedule a Lecture</h3>
                                    <div ref={this.contextualActionContainer} className="column medium-1">
                                        <button className="icon icon-pluscircle"
                                            onClick={this._showContextualDialog} />
                                    </div>
                                </React.Fragment>
                            }
                            content={
                                <React.Fragment>
                                    <div className="row" style={{padding: "20px 26px"}}>
                                        <div className="row item-content">
                                            <p className="column medium-6 title">
                                                End time:
                                            </p>
                                            <div className="column medium-3 status">

                                                <DurationSelector
                                                    startDate={new Date()}
                                                    duration={60}
                                                    dateStrings={DateTimeFormatter.getLanguagePack()}
                                                    dateFormatter={DateTimeFormatter}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>
                    <div className="module-section">
                        <DashboardModule
                            className="events-module"
                            header={
                                <React.Fragment>
                                    <h3 className="column medium-11">Events</h3>
                                </React.Fragment>
                            }
                            content={
                                <React.Fragment>
                                    <ul className="module-item-list">
                                        <li className="row">
                                            <div className="row item-content">
                                                <p className="column medium-6 title">
                                                    <a href="javascript:void(0);">London Group Interviews</a>
                                                </p>
                                                <div className="column medium-3 status">

                                                    <Slider
                                                        value={40}
                                                        caption="4/10 Complete"
                                                    />

                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            }
                            actions={
                                <React.Fragment>
                                    <a href="javascript:void(0)">View all events
                                        <span className="icon icon-after icon-chevronrightcircle"/>
                                    </a>
                                </React.Fragment>
                            }
                        />
                    </div>

                    <div className="actions">
                        <Button title="Modal dialog" onClick={this._showModalDialog} />
                        <Button title="Modal sheet" onClick={this._showModalSheet} />
                        <Button title="Notifications" onClick={this._showNotificationPanel} />
                    </div>

                    <div ref={this.contextualActionContainer2} className="module-section contextual-container"
                        style={{width: "50%", textAlign: "right",
                            height: "40px", marginTop: "30px"}}>
                        <p style={{display: "flex", "alignItems": "center"}}>
                            <strong style={{"flex": "auto", "marginRight": "8px"}}>Grid Systems{" "}</strong>
                            <button className="icon icon-info"
                                onClick={this._showOverflowContextualDialog} />
                        </p>
                    </div>
                </div>

                {showingModalDialog && <ModalDialog
                    onModalClose={this._onModalClose}>
                    <ListView
                        className="form-selector form-selector-rowwithgutters row"
                        items={events}
                        itemRenderer={this._itemRenderer}
                        onItemClicked={this._onListItemClicked}
                    />
                </ModalDialog>}

                {showingModalSheet && <ModalSheet
                    title="Duplicate Found"
                    okLabel="Go to duplicate"
                    type="status"
                    onOK={this._onOK}
                    onModalClose={this._onModalClose}>
                    <p>There&apos;s already a user with the given first name, last name, and email.</p>
                </ModalSheet>}

                {showingNotificationPanel && <NotificationPanel
                    onModalClose={this._onModalClose}>
                    <div className="notifications-wrapper">
                        <header><h3>Notifications</h3></header>
                        <div className="row summary">
                            <div className="column content medium-4 small-4">
                                <figure className="badge">
                                    <div className="badge-content" role="text">
                                        <span className="badge-value">3</span>
                                        <span className="badge-caption">Assigned Tasks</span>
                                    </div>
                                </figure>
                            </div>
                            <div className="column content medium-4 small-4">
                                <figure className="badge">
                                    <div className="badge-content" role="text">
                                        <span className="badge-value">5</span>
                                        <span className="badge-caption">Reminders</span>
                                    </div>
                                </figure>
                            </div>
                            <div className="column content medium-4 small-4">
                                <figure className="badge">
                                    <div className="badge-content" role="text">
                                        <span className="badge-value">7</span>
                                        <span className="badge-caption">Notifications</span>
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </div>
                </NotificationPanel>}

            </TabContent>
        );
    }
}
