/** -------------------------------------------------------------------------------------------------------------------
 * Pagination
 * A component that manages the pagination logic for a table view
 *
 * @examples
 *
 *  ```jsx
 *    <Pagination
 *      totalItems={28}
 *      numberOfPages={3}
 *      itemsPerPage={10}
 *      currentPage={1}
 *      onPaginationUpdated={(details) => console.log("Pagination updated")}
 *     />
 * ```
 *
 *  @component Pagination
 *  @import Pagination
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import { format } from "../../../utils/strings";

import "./pagination.scss";


export default class Pagination extends Component {

    constructor(props) {
        super(props);

        const numberOfPages = Math.ceil(props.totalItems / props.itemsPerPage);
        const pageIndicatorDisabled = props.totalItems <= props.itemsPerPage;
        this.state = {
            currentPage: 1,
            numberOfPages,
            pageIndicatorDisabled,
            previousDisabled: false,
            nextDisabled: false
        };
    }

    _onPaginationChanged = (event) => {
        this.setState({
            currentPage: event.target.value
        });
    };

    _gotoNextPage = () => {
        const { numberOfPages } = this.state;
        let { currentPage } = this.state;

        if (currentPage < numberOfPages) {
            ++currentPage;
            this._updatePaginationState(currentPage);
        }
    };

    _gotoPreviousPage = () => {
        let { currentPage } = this.state;

        if (currentPage > 1) {
            --currentPage;
            this._updatePaginationState(currentPage);
        }
    };

    _gotoSelectedPage = (event) => {
        const { numberOfPages } = this.state;
        const pageNumber = event.target.value;
        let currentPage;

        if (!isNaN(pageNumber)) {
            const isWithinRange = ((pageNumber >= 1) && (pageNumber <= numberOfPages));

            if (isWithinRange) {
                currentPage = pageNumber;
            } else {
                currentPage = (pageNumber < 1) ? 1 : numberOfPages;
            }
        } else {
            currentPage = 1;
        }

        this._updatePaginationState(currentPage);
    };

    _updatePaginationState(currentPage) {
        const { totalItems, itemsPerPage } = this.props;
        const { numberOfPages } = this.state;

        const previousDisabled = (currentPage <= 1);
        const nextDisabled = ((numberOfPages === 0) || (currentPage >= numberOfPages));
        const pageIndicatorsDisabled = (numberOfPages === 0);
        const pageOffset = (currentPage - 1) * itemsPerPage;

        this.setState({
            currentPage,
            previousDisabled,
            nextDisabled,
            pageIndicatorsDisabled
        });

        const start = ((currentPage - 1) * itemsPerPage) + 1;
        let end = currentPage * itemsPerPage;
        end = (end >= totalItems) ? totalItems : end;

        if (this.props.onPaginationUpdated) {
            this.props.onPaginationUpdated({
                currentPage,
                pageOffset,
                start,
                end,
                totalItems
            });
        }
    }

    componentDidMount() {
        this._updatePaginationState(this.state.currentPage);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.totalItems !== this.props.totalItems) {
            const {
                totalItems,
                itemsPerPage
            } = this.props;
            const numberOfPages = Math.ceil(totalItems / itemsPerPage);
            const currentPage = 1;
            const previousDisabled = (currentPage <= 1);
            const nextDisabled = ((numberOfPages === 0) || (currentPage >= numberOfPages));

            this.setState({
                numberOfPages,
                currentPage,
                previousDisabled,
                nextDisabled,
                pageIndicatorDisabled: totalItems <= itemsPerPage
            });
        }
    }

    render() {
        const {
            paginationTemplate
        } = this.props;
        const {
            numberOfPages,
            currentPage,
            previousDisabled,
            nextDisabled,
            pageIndicatorDisabled
        } = this.state;

        return (
            <nav className="pagination">
                <ul>
                    <li className="paddle-nav previous">
                        <a
                            href="javascript:void(0);"
                            className="previous"
                            aria-label="Previous"
                            disabled={previousDisabled}
                            onClick={this._gotoPreviousPage}>
                            <span className="icon icon-arrow-left" />
                        </a>
                    </li>
                    <li>
                        <input
                            type="text"
                            className="form-input form-input-text"
                            value={currentPage}
                            disabled={pageIndicatorDisabled}
                            onChange={this._onPaginationChanged}
                            onBlur={this._gotoSelectedPage} />
                        <span className="total">
                            <em>
                                {format(paginationTemplate, {
                                    total: numberOfPages
                                })}
                            </em>
                        </span>
                    </li>
                    <li className="paddle-nav next">
                        <a
                            href="javascript:void(0);"
                            className="next"
                            aria-label="Next"
                            disabled={nextDisabled}
                            onClick={this._gotoNextPage}>
                            <span className="icon icon-arrow-right" />
                        </a>
                    </li>
                </ul>
            </nav>
        );
    }
}

Pagination.propTypes = {
    totalItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number,
    paginationTemplate: PropTypes.string,
    onPaginationUpdated: PropTypes.func
};

Pagination.defaultProps = {
    itemsPerPage: 10,
    onPaginationUpdated: () => {}
};
