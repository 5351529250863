/** -------------------------------------------------------------------------------------------------------------------
 * MultiSelect
 * A component that renders a list of items with checkboxes, and allows for the multiple selection of such items
 *
 * @examples
 *
 *  ```jsx
 *    <MultiSelect
 *      header="Select an item"
 *      itemList={[]}
 *      onItemsSelected={(items) => console.log("Items selected: ", items)}
 *    />
 * ```
 *
 *  @component MultiSelect
 *  @import {MultiSelect}
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Checkbox from "../checkbox";
import InputField from "../input-field";
import Button from "../button";

import "./multi-select.scss";


export default class MultiSelect extends Component {

    constructor(props) {
        super(props);

        const selectedItems = props.itemList.filter((item) => !!item.selected).map((item) => item.id);
        this.state = {
            filteredItems: props.itemList,
            selectedItems
        };
    }

    _onFilterChange = (text) => {
        text = text.trim().toLowerCase();
        const keywords = (!text.length) ? [] : text.split(/[\s+|,]/);

        const filteredItems = (!text.length) ? this.props.itemList :
            this.props.itemList.filter((item) => {
                return keywords.some((keyword) => item.title.toLowerCase().includes(keyword));
            });

        this.setState({
            filteredItems
        });
    }

    _onItemSelected = (id, checked) => {
        const { selectedItems } = this.state;

        if (checked) {
            if (!selectedItems.includes(id)) {
                selectedItems.push(id);
            }
        } else {
            if (selectedItems.includes(id)) {
                selectedItems.splice(selectedItems.indexOf(id), 1);
            }
        }

        this.setState({
            selectedItems
        });

        if (this.props.onItemsSelected) {
            this.props.onItemsSelected(selectedItems);
        }
    }

    _generateItems() {
        const { selectedItems } = this.state;

        return this.state.filteredItems.map((item) => (
            <li key={item.id}>
                <Checkbox
                    key={item.id}
                    id={item.id}
                    label={item.title}
                    checked={selectedItems.includes(item.id)}
                    onChange={this._onItemSelected}
                />
            </li>
        ));
    }

    _onSelectAll = () => {
        const selectedItems = this.props.itemList.map((item) => {
            item.selected = true;
            return item;
        });

        this.setState({
            selectedItems
        });
    }

    _onDeselectAll = () => {
        this.props.itemList.forEach((item) => {
            item.selected = false;
        });
        this.setState({
            selectedItems: []
        });
    }

    _onSubmit = () => {
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }

    _onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        const { header, emptyListMessage, className } = this.props;
        const { filteredItems, selectedItems } = this.state;
        const isEmpty = !filteredItems.length;
        const selectedItemsMessage = `${selectedItems.length} selected`;

        return (
            <div className={classNames("multi-select-wrapper", className)}>
                <div className="header">
                    <h3>{header}</h3>
                    <InputField
                        placeholder="Filter by"
                        onChange={this._onFilterChange}
                    />
                    <p className="selected-count">{selectedItemsMessage}</p>
                </div>
                <div className="content">
                    {!isEmpty && <ul>{this._generateItems()}</ul> }
                    {isEmpty && <p className="info-message"><span>{emptyListMessage}</span></p>}
                </div>
                <div className="actions">
                    <Button title="Cancel" secondary={true} compact={true} onClick={this._onCancel} />
                    <Button title="Add" type="submit" compact={true} onClick={this._onSubmit} />
                </div>
            </div>
        );
    }
}

MultiSelect.propTypes = {
    header: PropTypes.string,
    className: PropTypes.string,
    emptyListMessage: PropTypes.string,
    itemList: PropTypes.array,
    onItemsSelected: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
};

MultiSelect.defaultProps = {
    header: "",
    className: "",
    itemList: [],
    emptyListMessage: "No Items Found",
    onItemsSelected: () => {},
    onSubmit: () => {},
    onCancel: () => {}
};
