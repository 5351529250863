/** ------------------------------------------------------------------------------------------------------------------
 * ModalDialog
 * A component that renders given its child components in a modal dialog
 *
 * @examples
 *
 *  ```jsx
 *    <ModalDialog
 *      modalDidOpen={() => console.log("Modal did open")}
 *      modalWillClose={() => console.log("Modal will close")}
 *      onModalClose={() => console.log("Modal did close")}>
 *      <div>Content goes here</div>
 *    </ModalDialog>
 *    />
 * ```
 *
 *  @component ModalDialog
 *  @import ModalDialog
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "../button";

import "./modal.scss";

export default class ModalDialog extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.modalDidOpen) {
            this.props.modalDidOpen();
        }
    }

    _onClose = () => {
        if (this.props.modalWillClose) {
            this.props.modalWillClose();
        }

        if (this.props.onModalClose) {
            this.props.onModalClose();
        }
    };

    _onDismiss = () => {
        const { autoDismiss, onModalClose } = this.props;

        if (autoDismiss && onModalClose) {
            onModalClose();
        }
    };

    _onConfirm = () => {
        if (this.props.onModalConfirm) {
            this.props.onModalConfirm();
        }
    };

    render() {
        const {
            mode,
            title,
            className,
            okLabel,
            cancelLabel,
            children,
            showOKButton,
            showCancelButton,
            okButtonDisabled
        } = this.props;

        return (
            <div className={classNames("modal", `modal-${mode}`, className)} aria-hidden="false" role="dialog">
                <div className="backdrop" onClick={this._onDismiss} />
                <div className="content-wrapper">
                    <div className="content-padding">
                        <header className="modal-header">
                            <h3>{title}</h3>
                        </header>
                        <div className="modal-content">
                            {children}
                        </div>
                        <div className="row actions bordered">
                            {showCancelButton && <Button
                                title={cancelLabel}
                                secondary={true}
                                compact={true}
                                onClick={this._onClose}
                            />}
                            {showOKButton && <Button
                                title={okLabel}
                                compact={true}
                                disabled={okButtonDisabled}
                                onClick={this._onConfirm}
                            />}
                        </div>
                        <button
                            onClick={this._onClose}
                            className="icon icon-close modal-close"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ModalDialog.propTypes = {
    mode: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    showOKButton: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    okButtonDisabled: PropTypes.bool,
    modalDidOpen: PropTypes.func,
    modalWillClose: PropTypes.func,
    onModalClose: PropTypes.func,
    onModalConfirm: PropTypes.func,
    autoDismiss: PropTypes.bool
};

ModalDialog.defaultProps = {
    mode: "standard",
    title: "Modal Dialog",
    okLabel: "OK",
    cancelLabel: "Cancel",
    showOKButton: true,
    showCancelButton: true,
    okButtonDisabled: false,
    autoDismiss: true,
    modalDidOpen: () => {},
    modalWillClose: () => {},
    onModalClose: () => {},
    onModalConfirm: () => {}
};
