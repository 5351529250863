import React, { Component } from "react";
import PropTypes from "prop-types";

import navLinks from "../../store/data/nav-links";
import userInfo from "../../store/data/user-info";
import TabbedView from "../shared/tabbed-view";
import LocalNav from "../shared/local-nav";
import { GlobalNav } from "../shared/global-nav";

import { ButtonView } from "./button-view";
import { NotificationView } from "./notification-view";
import { FormElementsView } from "./form-view";
import { DashboardView } from "./dashboard-view";
import TableView from "./table-view";
import CalendarView from "./calendar-view";

import "../../../src/styles/patterns/patterns.scss";

const props = {
    title: "1UA",
    links: navLinks,
    auth: {
        userInfo: {
            id: "jsmith",
            firstName: "John",
            lastName: "Smith"
        }
    },
    context: {
        contextPath: "/",
        locale: "en"
    },
    i18n: {
        getString: (string) => {
            const value = string.split(".")[1];
            return `${value.charAt(0).toUpperCase()}${value.substring(1)}`;
        }
    },
    match: {
        params: {
            id: "mgmt-5000"
        },
        isExact: true
    },
    activateUserSession: () => {},
    logout: () => {}
};


class PatternLibrary extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className="pattern-library">

                <GlobalNav
                    {...props}
                    user={userInfo}
                />

                <LocalNav
                    title="Pattern Library"
                    page="dashboard"
                    caption="Graphene Style Kit"
                    className="section-local-nav"
                    showPageInfo={true}
                    showLinks={false}
                    showContextualAction={false}
                />

                <div className="module-section" style={{minHeight: "250px"}}>
                    <TabbedView
                        layout="vertical"
                        tabContent={[
                            <CalendarView
                                i18n={this.props.i18n}
                                key="calendar"
                                title="Calendars"
                                className="tab-content"
                            />,
                            <TableView key="table" title="Table View" className="tab-content" />,
                            <ButtonView key="buttons" title="Buttons" />,
                            <FormElementsView key="forms" title="Form Elements" className="tab-content" />,
                            <NotificationView key="notifications" title="Notifications" className="tab-content" />,
                            <DashboardView key="dashboard" title="Dashboard" className="tab-content" />
                        ]}
                    />
                </div>

            </section>
        );
    }
}

PatternLibrary.propTypes = {
    title: PropTypes.string
};

PatternLibrary.defaultProps = {
    title: "Pattern Library"
};

export default PatternLibrary;
