/** -------------------------------------------------------------------------------------------------------------------
 * ListView
 * A component that renders a list view of items based on a given renderer function
 *
 * @examples
 *
 *  ```jsx
 *    <ListView
 *      items={events}
 *      itemRenderer={(item, index) => <li key={index}>{item.name}</li>}
 *      onItemClicked={(event) => console.log("Item clicked: ", event)}
 *    />
 * ```
 *
 *  @component ListView
 *  @import ListView
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


const ListView = ({
    items,
    itemRenderer,
    className,
    onItemClicked
}) => (
    <ul className={classNames("list-view-wrapper", className)} onClick={onItemClicked}>
        {items.map((item, index) => itemRenderer(item, index))}
    </ul>
);


const itemRenderer = (item, index) => <li key={index}>{item.name}</li>;

ListView.propTypes = {
    items: PropTypes.array.isRequired,
    itemRenderer: PropTypes.func.isRequired,
    className: PropTypes.string,
    onItemClicked: PropTypes.func
};

ListView.defaultProps = {
    items: [],
    itemRenderer,
    onItemClicked: () => {}
};

export default ListView;
