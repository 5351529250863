import React, { Component } from "react";
import PropTypes from "prop-types";
import { TabContent } from "../shared/tabbed-view/tab-content";
import Table from "../shared/table";
import { talentColumns } from "../../store/data/talent-columns";
import { talents } from "../../store/data/talents";


const cellRenderer = (item, columnField) => {

    switch (columnField) {

        case "talentFullName":
            return (
                <a key={item.talent.talentID}
                    rel={item.id}
                    href={`/talent/${item.talent.talentID}/overview`}
                    data-type="talentNameLink">
                    {item.talent.firstName} {item.talent.lastName}
                </a>
            );

        case "talentID":
            return (
                <span data-type={columnField}>{item.talent.talentID}</span>
            );

        default:
            return (
                <span data-type={columnField}>{item[columnField]}</span>
            );
    }
};

const detailsColumns = [
    "preferredEmail",
    "educationDegrees",
    "schools",
    "majorFields",
    "willingToRelocate",
    "languages",
    "globalStatus",
    "connectionSource",
    "connectionOwner",
    "previousStatus"
];

const cellDetailsRenderer = (item) => {
    return (
        <div className="item-details">
            {detailsColumns.map((column, index) => (
                <p key={index}>
                    <strong>{column}:</strong>
                    <span>{item[column]}</span>
                </p>
            ))}
        </div>
    );
};

const sortFunctions = (key, direction = "asc") => {

    switch (key) {

        case "talentFullName": {
            return (a, b) => {
                const aFullName = `${a.talent.firstName} ${a.talent.lastName}`;
                const bFullName = `${b.talent.firstName} ${b.talent.lastName}`;

                return direction === "asc" ? aFullName.localeCompare(bFullName) : bFullName.localeCompare(aFullName);
            };

        }

        case "talentID":
            return (a, b) => {
                const aTalentID = `${a.talent.talentID}`;
                const bTalentID = `${b.talent.talentID}`;
                return direction === "asc" ? aTalentID.localeCompare(bTalentID) : bTalentID.localeCompare(aTalentID);
            };

        case "employerNames":
            return (a, b) => {
                return direction === "asc" ? a.employerNames.localeCompare(b.employerNames) :
                    b.employerNames.localeCompare(a.employerNames);
            };

        default:
            return (a, b) => {
                return direction === "asc" ? a.id < b.id : a.id > b.id;
            };
    }
};


export default class TableView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allItemsExpanded: false
        };
    }

    _onRowClicked = (event) => {
        const { items } = this.props;

        switch (event.target.tagName.toLowerCase()) {

            case "a":
                event.preventDefault();
                break;

            case "button": {
                const itemId = event.target.id.split("-")[1];
                const currentItem = items.find((item) => `${item.id}` === itemId);
                currentItem.expanded = !currentItem.expanded;
                this.forceUpdate();

                break;
            }

            default:
                break;
        }
    };

    _onBulkContextualAction = (event, items) => {
        let { allItemsExpanded } = this.state;
        allItemsExpanded = !allItemsExpanded;

        items.forEach((item) => {
            item.expanded = allItemsExpanded;
        });

        this.setState({
            allItemsExpanded
        });
    };

    render() {
        const {
            columns,
            items
        } = this.props;

        return (
            <div className="table-wrapper">
                <TabContent key="tableView" title="Table View" className="tab-content">
                    <div className="table-section">

                        <Table
                            columns={columns}
                            items={items}
                            cellRenderer={cellRenderer}
                            cellDetailsRenderer={cellDetailsRenderer}
                            showContextualMenu={true}
                            sortFunctions={sortFunctions}
                            onRowClicked={this._onRowClicked}
                            onBulkContextualAction={this._onBulkContextualAction}
                        />

                    </div>
                </TabContent>
            </div>
        );
    }
}


TableView.propTypes = {
    columns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired
};

TableView.defaultProps = {
    columns: talentColumns,
    items: talents
};

