/** -------------------------------------------------------------------------------------------------------------------
 * ColorSelector
 * A component that renders a list of color codes and allows users to select a given one
 *
 * @examples
 *
 *  ```jsx
 *    <ColorSelector
 *      onColorSelected={(colorCode, selected) => console.log("onColorSelected: %s: %s", colorCode, selected)}
 *    />
 * ```
 *
 *  @component ColorSelector
 *  @import {ColorSelector}
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./color-selector.scss";


export default class ColorSelector extends Component {

    constructor(props) {
        super(props);

        const selectionStates = {};
        const currentColor = props.selectedColor ?
            props.colors.find((c) => c.code === props.selectedColor.toLowerCase()) : null;

        if (currentColor) {
            selectionStates[currentColor.code] = true;
        }

        this.state = {
            selectionStates,
            currentColor
        };
    }

    _onColorSelected = (event) => {
        const { target } = event;
        const colorCode = target.id.split("_")[1];
        const { selectionStates, currentColor } = this.state;

        if (currentColor && currentColor.code !== colorCode) {
            selectionStates[currentColor.code] = false;
        }

        const selectedColor = this.props.colors.find((c) => c.code === colorCode);
        selectionStates[selectedColor.code] = target.checked;

        this.setState({
            selectionStates,
            currentColor: selectedColor
        });

        if (this.props.onColorSelected) {
            this.props.onColorSelected(selectedColor.code, target.checked);
        }
    }

    render() {
        const { colors, prefix, className } = this.props;
        const { selectionStates } = this.state;

        return (
            <ul className={classNames("colornav-items", className)}>
                {colors.map((color, index) => {
                    const id = `${prefix}_${color.code}`;

                    return (
                        <li key={index} className="colornav-item">
                            <input
                                type="checkbox"
                                name="color-code-selection"
                                checked={!!selectionStates[color.code]}
                                className={`colornav-value color-${color.code}`}
                                id={id}
                                onChange={this._onColorSelected} />
                            <label htmlFor={id} className={classNames("colornav-link", {
                                current: !!selectionStates[color.code]
                            })}>
                                <figure className={`colornav-swatch color-${color.code}`}>
                                    <span className="icon icon-reset" />
                                </figure>
                            </label>
                        </li>
                    );

                })}
            </ul>
        );
    }
}

ColorSelector.colorCodes = [
    {color: "red", code: "red"},
    {color: "orange", code: "orange"},
    {color: "yellow", code: "yellow"},
    {color: "green", code: "green"},
    {color: "blue", code: "blue"},
    {color: "purple", code: "purple"},
    {color: "grey", code: "grey"}
];

ColorSelector.propTypes = {
    colors: PropTypes.array,
    prefix: PropTypes.string,
    className: PropTypes.string,
    selectedColor: PropTypes.string,
    onColorSelected: PropTypes.func
};

ColorSelector.defaultProps = {
    colors: ColorSelector.colorCodes,
    prefix: "selection",
    className: "",
    onColorSelected: () => {}
};
