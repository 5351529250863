/** ------------------------------------------------------------------------------------------------------------------
 * Slider
 * A component that renders a slider with its current value and associated label
 *
 * @examples
 *
 *  ```jsx
 *     <Slider
 *         value={40}
 *         caption="4/10 Complete"
 *     />
 * ```
 *
 *  @component Slider
 *  @import Slider
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./slider.scss";

const Slider = ({
    caption,
    value,
    mode,
    captionPosition,
    className
}) => {
    const classList = classNames("slider", className, mode, {
        "caption-right": captionPosition === "right"
    });
    const progress = Math.min(value, 100);

    return (
        <span className={classList}>
            <span className="track">
                <span className="bar" style={{width: `${progress}%`}} />
            </span>
            {caption && <span className="slider-caption">{caption}</span>}
        </span>
    );
};

Slider.propTypes = {
    caption: PropTypes.string,
    mode: PropTypes.string,
    value: PropTypes.number,
    captionPosition: PropTypes.string,
    className: PropTypes.string
};

Slider.defaultProps = {
    captionPosition: "bottom",
    mode: "normal",
    className: ""
};

export default Slider;
