import * as Numbers from "./numbers";

let locale;
const languagePacks = {};

const months = ["January", "February", "March", "April", "May", "June", "July", "August",
    "September", "October", "November", "December"];
const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const mediumWeekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const shortWeekDays = ["S", "M", "T", "W", "T", "F", "S"];

const relativeDate = {
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    TOMORROW: "Tomorrow",
    HOUR_FORMAT: "%H:%M",
    DATE_FORMAT: "%b",
    YEAR_FORMAT: "%Y"
};

const dateFormat = {
    SHORT: "%Y/%m/%D",
    SHORT_DASHED: "%Y-%m-%D",
    MEDIUM: "%a, %b %d",
    LONG: "%A, %B %d, %Y",
    STANDARD: "%A, %B %d",
    MONTH_DAY: "%B %d",
    MONTH_YEAR: "%B %Y",
    MONTH_DAY_YEAR: "%B %d, %Y",
    ISO: "%Y-%m-%DT%H:%M:%S",
    UTC: "%Y-%m-%DT%H:%M:%SZ"
};

const timeFormat = {
    SHORT: "%j:%M %p",
    MEDIUM: "%j:%M %p",
    HOURS: "%j %p",
    DATE_TIME_SHORT: "%a, %b %d %j:%M %p"
};

const calendar = {
    Month: {
        JAN: 0,
        FEB: 1,
        MAR: 2,
        APR: 3,
        MAY: 4,
        JUN: 5,
        JUL: 6,
        AUG: 7,
        SEP: 8,
        OCT: 9,
        NOV: 10,
        DEC: 11
    },

    WeekDay: {
        SUN: 0,
        MON: 1,
        TUE: 2,
        WED: 3,
        THU: 4,
        FRI: 5,
        SAT: 6
    }
};

export const addLanguagePack = (newLocale = "en", strings = {}) => {
    locale = newLocale;

    languagePacks[locale] = {
        shortWeekDays: strings.shortWeekDays || [],
        mediumWeekDays: strings.mediumWeekDays || [],
        weekDays: strings.weekDays || [],
        shortMonths: strings.shortMonths || [],
        months: strings.months || [],
        relativeDate: strings.relativeDate || {},
        dateFormat: strings.dateFormat || {},
        timeFormat: strings.timeFormat || {},
        calendar: strings.calendar || calendar
    };
};

export const getLanguagePack = () => languagePacks[locale];

export const getCurrentLocale = () => locale;

/** Register the default language pack for English */
export const setDefaultLocale = () => {
    addLanguagePack("en", {
        shortWeekDays,
        weekDays,
        mediumWeekDays,
        shortMonths,
        months,
        relativeDate,
        dateFormat,
        timeFormat,
        calendar
    });
};
setDefaultLocale();

const stringFormats = {
    "%a": (date) => getLanguagePack().mediumWeekDays[date.getDay()],
    "%A": (date) => getLanguagePack().weekDays[date.getDay()],
    "%b": (date) => getLanguagePack().shortMonths[date.getMonth()],
    "%B": (date) => getLanguagePack().months[date.getMonth()],
    "%c": (date) => date.toLocaleString(),
    "%d": (date) => Numbers.toPaddedString(date.getDate(), 1),
    "%D": (date) => Numbers.toPaddedString(date.getDate(), 2),
    "%H": (date) => Numbers.toPaddedString(date.getHours(), 2),
    "%h": (date) => Numbers.toPaddedString(date.getHours(), 1),
    "%I": (date) => Numbers.toPaddedString((date.getHours() % 12), 2),
    "%j": (date) => {
        return (date.getHours() % 12 === 0) ? 12 : Numbers.toPaddedString(date.getHours() % 12, 1);
    },
    "%m": (date) => Numbers.toPaddedString((date.getMonth() + 1), 2),
    "%M": (date) => Numbers.toPaddedString(date.getMinutes(), 2),
    "%n": (date) => date.getMonth() + 1,
    "%q": (date) => Numbers.toPaddedString(date.getMonth() + 1, 2),
    "%t": (date) => date.getDate(),
    "%p": (date) => {
        return Math.floor(date.getHours() / 12) === 0 ? "AM" : "PM";
    },
    "%P": (date) => {
        return Math.floor(date.getHours() / 12) === 0 ? "AM" : "PM";
    },
    "%S": (date) => Numbers.toPaddedString(date.getSeconds(), 2),
    "%y": (date) => date.getFullYear().toString().substring(2),
    "%Y": (date) => Numbers.toPaddedString(date.getFullYear(), 4),
    "%W": (date) => getLanguagePack().weekDays[date.getDay()]
};

export const weekdayName = (index, shortNames = true) => {
    const languagePack = getLanguagePack();
    return shortNames ? languagePack.shortWeekDays[index] : languagePack.weekDays[index];
};

export const fromDateComponents = (year, month, date, hours = 0, minutes = 0, seconds = 0) => {
    return new Date(year, month, date, hours, minutes, seconds);
};

export const format = (date, dateFormat) => {
    return dateFormat.replace(/%[a-zA-Z]/g, (pattern) => {
        const formatter = stringFormats[pattern];
        return formatter(date).toString();
    }).replace(/%%/, "%");
};

export const formatDate = format;

export const equals = (date1, date2, includeTime = true) => {
    const datesEquals = (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate());

    if (!includeTime) {
        return datesEquals;
    }

    return datesEquals && (date1.getHours() === date2.getHours() &&
        date1.getMinutes() === date2.getMinutes() &&
        date1.getSeconds() === date2.getSeconds());
};

export const isLeapYear = (date) => {
    const year = date.getFullYear();
    return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
};

export const fromUTCString = (utcString) => new Date(Date.parse(utcString));

export const getLocalTime = (timezoneOffset) => {
    if (typeof timezoneOffset !== "number") {
        return new Date();
    }

    const ONE_MINUTE = 60 * 1000;
    const currentTime = new Date();
    const currentTimeUTC = currentTime.getTime() + currentTime.getTimezoneOffset() * ONE_MINUTE;

    return new Date(currentTimeUTC + timezoneOffset);
};


export const formatInRelativeTimeUnit = (timeInterval, i18n) => {
    const { format } = i18n;

    const hours = Math.floor(timeInterval / 3600);
    const days = Math.floor(hours / 24);
    const minutes = Math.floor((timeInterval / 60) % 60);
    const seconds = Math.floor(timeInterval % 60);
    const adjustedHours = (days > 0) ? hours - days * 24 : hours;

    /* eslint-disable indent, max-len */
    return (days > 0) ? format("date.timeUnitDays", [`${days}`]) :
            (adjustedHours > 0) ? format("date.timeUnitHours", [`${adjustedHours}`]) :
            (minutes > 0) ? format("date.timeUnitMinutes", [`${minutes}`]) : format("date.timeUnitSeconds", [`${seconds}`]);
};

