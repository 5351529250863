/** -------------------------------------------------------------------------------------------------------------------
 * InputField
 * A component that renders an input field with validation capabilities
 *
 * @examples
 *
 *  ```jsx
 *    <InputField
 *      type="text"
 *      validationTypes={["isRequired","isEmail"]}
 *      validators={{
 *        isRequired: {
 *          message: "This field is required"
 *        }
 *      }}
 *      label="First name"
 *      value=""
 *      error=""
 *      className=""
 *      isSearchField={true}
 *      showClearControl={false}
 *      onChange={(value) => console.log(`Updated: ${value}`)}
 *      onValid={(value) => console.log(`Valid: ${value}`)}
 *      onError={(value, error) => console.log(`error: ${value} - ${error}`)}
 *    />
 * ```
 *
 *  @component InputField
 *  @import {InputField}
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import { defaultValidators } from "../utils/validators";
import getId from "../utils/get-id";
import classNames from "classnames";

import "./input-field.scss";


export default class InputField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: `inputField-${getId()}`,
            currentValidators: this._generateValidators(),
            value: props.value,
            error: props.error
        };
    }

    _generateValidators() {
        const { validationTypes, validators } = this.props;

        return !Array.isArray(validationTypes) ? {} : validationTypes.reduce((result, current) => {
            const hasDefaultValidator = defaultValidators[current];
            const validator = (hasDefaultValidator) ? Object.assign({}, defaultValidators[current],
                validators[current] || {}) : validators[current] || {};
            result[current] = validator;

            return result;
        }, {});
    }

    _onChange = (event) => {
        this.setState({
            value: event.target.value,
            error: ""
        });

        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    _onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    _onBlur = (event) => {
        const { currentValidators } = this.state;

        if (Object.keys(currentValidators).length) {
            this._validate();
        }

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    _onKeypress = (event) => {
        if (event.charCode === 13) {
            const errorMessage = this._isValid();

            if (!errorMessage && this.props.onSubmit) {
                this.props.onSubmit(event);

                if (this.props.resetValueOnSubmit) {
                    this.setState({
                        value: ""
                    });
                }
            }
        }
    };

    _validate = () => {
        const { onValid, onError } = this.props;
        const { value } = this.state;
        const errorMessage = this._isValid();

        if (!errorMessage.length) {
            this.setState({
                error: ""
            });
            onValid(value);
        } else {
            this.setState({
                error: errorMessage
            });
            onError(value, errorMessage);
        }
    }

    _isValid() {
        const { currentValidators, value } = this.state;
        let error = "";

        Object.keys(currentValidators).every((key) => {
            const validator = currentValidators[key].test;
            const valid = (validator && typeof validator === "function") ? validator(value) : true;

            if (!valid) {
                error = currentValidators[key].message || key;
            }

            return valid;
        });

        return error;
    }

    _onClear = () => {
        this._input.focus();
        this.setState({
            value: "",
            error: ""
        });

        if (this.props.onChange) {
            this.props.onChange("");
        }
    }

    _setRef = (input) => {
        this._input = input;

        if (this.props.onRef) {
            this.props.onRef(input);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.validationTypes) {
            this.setState({
                currentValidators: this._generateValidators()
            });
        }

        if (nextProps.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    render() {
        const {
            className,
            type,
            isSearchField,
            showClearControl,
            autocomplete,
            tabIndex,
            label,
            placeholder,
            disabled,
            maxLength
        } = this.props;

        const {
            id,
            value,
            error
        } = this.state;

        const shouldShowClearControl = !!(!disabled && showClearControl && value.length);
        const classList = classNames(className, "form-input form-input-text form-icon-right", {
            disabled,
            invalid: !!error,
            "form-icon-left": isSearchField
        });

        return (
            <div className="input-field-wrapper">
                {label && <label className="form-cell form-label" htmlFor={id}>{label}</label>}
                <div className={classNames({ "form-element": isSearchField })}>
                    <input
                        id={id}
                        ref={this._setRef}
                        type={type}
                        placeholder={placeholder}
                        autoComplete={autocomplete}
                        tabIndex={tabIndex}
                        value={value}
                        onChange={this._onChange}
                        onFocus={this._onFocus}
                        onBlur={this._onBlur}
                        onKeyPress={this._onKeypress}
                        className={classList}
                        disabled={disabled}
                        maxLength={maxLength ? maxLength : null}
                    />
                    {isSearchField && <label className={`form-icons-wrapper form-icons-wrapper-left 
                        form-icons-focusable`}>
                        <span className="form-icons form-icons-search15" />
                    </label>}
                    {shouldShowClearControl && <div className="form-icons-wrapper form-icons-focusable">
                        <button className="form-icons form-icons-small form-icons-clearsolid15"
                            onClick={this._onClear} />
                    </div>}
                    {error && <label className="form-message-wrapper error-label">
                        <span>{error}</span>
                    </label>}
                </div>
            </div>
        );
    }
}

InputField.propTypes = {
    type: PropTypes.oneOf(["text", "search", "email", "number", "tel", "url", "password"]).isRequired,
    validationTypes: PropTypes.array,
    validators: PropTypes.object,
    isSearchField: PropTypes.bool,
    showClearControl: PropTypes.bool,
    autocomplete: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func,
    onValid: PropTypes.func,
    onError: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onSubmit: PropTypes.func,
    onRef: PropTypes.func,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    resetValueOnSubmit: PropTypes.bool
};

InputField.defaultProps = {
    type: "text",
    validationTypes: [],
    isSearchField: false,
    showClearControl: true,
    validators: {},
    autocomplete: "off",
    label: "",
    placeholder: "",
    value: "",
    error: "",
    className: "",
    tabIndex: 0,
    disabled: false,
    onChange: () => {},
    onSubmit: () => {},
    onValid: () => {},
    onError: () => {},
    onRef: () => {}
};
