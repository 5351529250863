/** --------------------------------------------------------------------------------------------------------------------
 * LanguageSelector
 * A component that renders a list of languages, and allows for the selection of a given one
 *
 * @examples
 *
 *  ```jsx
 *    <LanguageSelector
 *      languages={languages}
 *      onLanguageSelected={(language) => console.log("Language selected: ", language)}
 *    />
 * ```
 *
 *  @component LanguageSelector
 *  @import LanguageSelector
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./language-selector.scss";


export default class LanguageSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedLanguage: this._findLanguageByLocale(props.locale),
            showingPanel: false
        };
    }

    _toggleLanguagePanel = () => {
        const shouldShow = !this.state.showingPanel;

        this.setState({
            showingPanel: shouldShow
        });

        if (shouldShow) {
            document.addEventListener("click", this._onDocumentClick);
        } else {
            document.removeEventListener("click", this._onDocumentClick);
        }
    };

    _onDocumentClick = () => {
        this._toggleLanguagePanel();
    };

    _onLanguageSelected = (event) => {
        const locale = event.target.getAttribute("data-locale");
        const selectedLanguage = this._findLanguageByLocale(locale);

        this.setState({
            selectedLanguage
        });

        if (this.props.onLanguageSelected) {
            this.props.onLanguageSelected(selectedLanguage);
        }

        this._toggleLanguagePanel();
    };

    _findLanguageByLocale(localeId) {
        const language = this.props.languages.find((language) => language.locale === localeId);
        return language || this.props.languages[0];
    }

    _renderLanguageList() {
        const { selectedLanguage } = this.state;

        return this.props.languages.map((language) => {
            const selected = (language.locale === selectedLanguage.locale);

            return (
                <li key={language.id} className={classNames({ selected })}>
                    <a href="javascript:void(0)" data-locale={language.locale}>
                        {language.displayName}
                        {selected && <i className="icon icon-checkmark" />}
                    </a>
                </li>
            );
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locale !== this.props.locale) {
            this.setState({
                selectedLanguage: this._findLanguageByLocale(this.props.locale)
            });
        }
    }

    render() {
        const {
            className,
            iconClassName
        } = this.props;
        const { showingPanel, selectedLanguage } = this.state;
        const iconClass = classNames("icon icon-chevron-down", iconClassName);

        return (
            <div className={classNames("language-selector", className)}>
                <p className="current-language">
                    <span>{selectedLanguage ? selectedLanguage.displayName : ""}</span>
                    <a href="javascript:void(0)" className="dropdown-selector" onClick={this._toggleLanguagePanel}>
                        <i className={iconClass} />
                    </a>
                </p>
                {showingPanel && <ul className="contextual-selector language-list" onClick={this._onLanguageSelected}>
                    {this._renderLanguageList()}
                </ul>}
            </div>
        );
    }
}

LanguageSelector.propTypes = {
    mode: PropTypes.string,
    selector: PropTypes.string,
    locale: PropTypes.string,
    languages: PropTypes.array.isRequired,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    onLanguageSelected: PropTypes.func
};

LanguageSelector.defaultProps = {
    locale: "en",
    selector: "country",
    mode: "locale-selector",
    languages: [],
    onLanguageSelected: () => {}
};
