export const defaultValidators = {
    isRequired: {
        test: (value) => !!value.trim().length,
        message: "This field is required"
    },

    isEmail: {
        test: (value) => !value.length ||
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value),
        message: "Please enter a valid email address"
    },

    isNumber: {
        test: (value) => !value.length || /^-?\d+\.?\d*$/.test(value),
        message: "Please enter a numeric value"
    },

    isZipCode: {
        test: (value) => !value.length || /^\d{5}$/.test(value),
        message: "Please enter a valid ZIP code"
    },

    isAlphaNumeric: {
        test: (value) => !value.length || /^[a-zA-Z0-9\s]+$/.test(value),
        message: "Please enter an alphanumeric value"
    },

    isTelephone: {
        test: (value) =>
            !value.length || /^\(?([0-9]{3})\)?([ .-]?)([0-9]{3})([ .-]?)([0-9]{4})$/.test(value),
        message: "Please enter a valid phone number"
    },

    isURL: {
        test: (value) =>
            !value.length || /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi.test(value),
        message: "Please enter a valid URL"
    }
};
