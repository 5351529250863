import React from "react";
import { TabContent } from "../shared/tabbed-view/tab-content";
import Toggle from "../shared/toggle";
import SegmentedControl from "../shared/segmented-control";
import MultiSelect from "../shared/multi-select";
import ColorSelector from "../shared/color-selector";
import Selector from "../shared/selector";
import itemList from "../../store/data/item-list";
import Tooltip from "../shared/tooltip";
import InputField from "../shared/input-field";
import TextArea from "../shared/text-area";
import DatePicker from "../shared/calendar/date-picker";
import * as DateTimeFormatter from "../../utils/dates";

import { InjectIntl } from "../../i18n/intl";

import MailComposer from "../shared/mail-composer";

const MailComposerComponent = InjectIntl(MailComposer);


/* eslint-disable no-console */
export const FormElementsView = () => (
    <TabContent key="notifications" title="Notifications" className="tab-content">
        <div className="component-wrapper">
            <div className="module-section">
                <Toggle
                    checked={true}
                    label="Enable notifications"
                    onChange={(id, checked) => console.log("Toggled - %s: %s", id, checked)}
                />
            </div>

            <div className="module-section">
                <SegmentedControl
                    segments={[
                        {id: "month", title: "Month view" },
                        {id: "list", title: "List view"}
                    ]}
                />
            </div>

            <div className="module-section">
                <div className="mail-wrapper">
                    <MailComposerComponent
                        title="Compose New Mail"
                        body="Hi. Join my university"
                        onSubmit={(data) => console.log("Sending mail: ", data)}
                    />
                </div>
            </div>

            <div className="module-section">
                <MultiSelect
                    header="Select items"
                    itemList={itemList}
                />
            </div>

            <div className="module-section">
                <ColorSelector
                    onColorSelected={(colorCode, selected) => console.log("onColorSelected: %s: %s",
                        colorCode, selected)}
                />
            </div>

            <div className="module-section">
                <Selector
                    label="Show"
                    className="filters"
                    options={[
                        {id: "latest", title: "Latest Activity" },
                        {id: "week", title: "Weekly Activities" },
                        {id: "month", title: "Monthly Activities" },
                        {id: "all", title: "All Activities" }
                    ]}
                    onChange={(event) => console.log("onChange: ", event.target.value)}
                />
            </div>

            <div className="module-section">
                <Tooltip
                    content="Passing a string to call() is deprecated and will be illegal in Sass 4.0"
                />
            </div>

            <div className="module-section">
                <InputField
                    isSearchField={true}
                    validationTypes={["isRequired"]}
                    placeholder="Enter your first name"
                />
            </div>

            <div className="module-section">
                <TextArea
                    placeholder="Write something cool"
                    value=""
                />
            </div>

            <div className="module-section">
                <DatePicker
                    dateStrings={DateTimeFormatter.getLanguagePack()}
                    dateFormatter={DateTimeFormatter}
                    onDateSelected={(date) => console.log("Date selected: ", date)}
                />
            </div>

        </div>
    </TabContent>
);
