import React from "react";
import NotificationBanner from "../shared/notification-banner";
import { TabContent } from "../shared/tabbed-view/tab-content";


export const NotificationView = () => (
    <TabContent key="notifications" title="Notifications" className="tab-content">
        <div className="component-wrapper">
            <div className="module-section">
                <NotificationBanner
                    message="This is informational text."
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="status"
                    message="Your submission is being processed..."
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="success"
                    message="Your submission was successful."
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="warning"
                    message="This operation could be harmful. You must choose wisely."
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="error"
                    message="There was a problem with your submission. Please try again."
                    showCloseButton={true}
                />
            </div>
            <div className="module-section">
                <NotificationBanner
                    message="This is informational text."
                    mode="toast"
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="status"
                    mode="toast"
                    message="Your submission is being processed..."
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="success"
                    mode="toast"
                    message="Your submission was successful."
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="warning"
                    mode="toast"
                    message="This operation could be harmful. You must choose wisely."
                    showCloseButton={true}
                />
                <NotificationBanner
                    type="error"
                    mode="toast"
                    message="There was a problem with your submission. Please try again."
                    showCloseButton={true}
                />
            </div>
        </div>
    </TabContent>
);
