/** -------------------------------------------------------------------------------------------------------------------
 * Navigation Links
 * A component that renders the navigation links in the header section
 *
 * @examples
 *
 * ```jsx
 *  <NavLinks
 *    links=[
 *      { id: "home", title: "Home", url: "/"}
 *    ]
 *  />
 * ```
 *
 *  @component NavLinks
 *  @import NavLinks
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const NavLinks = ({ links }) => (
    <ul className="menu-items medium-9">
        {
            links.map((link) => (
                <li key={link.id}
                    className={`menu-item item-${link.id}`}>
                    <NavLink
                        id={`navlink-${link.id}`}
                        to={link.url}
                        className="menu-link"
                        activeClassName={"current"}
                    >
                        <span>{link.title}</span>
                    </NavLink>
                </li>
            ))
        }
    </ul>
);

NavLinks.propTypes = {
    links: PropTypes.array
};

NavLinks.defaultProps = {
    links: []
};

export default NavLinks;
