export const talentColumns = [
    {
        "id": "talentFullName",
        "index": 1,
        "isDisabled": false,
        "isSelected": false,
        "headerName": "Talent",
        "dataField": "talentFullName",
        "localizedKey": "atp.talent.talentName",
        "sortable": true,
        "sortByDefault": false,
        "hidden": false,
        "configurable": true,
        "type": "link"
    },
    {
        "id": "pipeline",
        "index": 2,
        "isDisabled": false,
        "isSelected": false,
        "headerName": "Requisition ID",
        "dataField": "talentID",
        "localizedKey": "atp.talent.pipeline",
        "sortable": true,
        "sortByDefault": false,
        "hidden": false,
        "configurable": true,
        "type": "text"
    },
    {
        "id": "connection",
        "index": 3,
        "isDisabled": false,
        "isSelected": false,
        "headerName": "Regarding Connection",
        "dataField": "jobTitles",
        "localizedKey": "atp.talent.connection",
        "sortable": false,
        "sortByDefault": false,
        "hidden": false,
        "configurable": true,
        "type": "link"
    },
    {
        "id": "connectionType",
        "index": 4,
        "isDisabled": false,
        "isSelected": false,
        "headerName": "Connection Type",
        "dataField": "talentType",
        "localizedKey": "atp.talent.connectionType",
        "sortable": false,
        "sortByDefault": false,
        "hidden": false,
        "configurable": true,
        "type": "text"
    },
    {
        "id": "employer",
        "index": 5,
        "isDisabled": false,
        "isSelected": false,
        "headerName": "Employer",
        "dataField": "employerNames",
        "localizedKey": "atp.talent.employer",
        "sortable": true,
        "sortByDefault": false,
        "hidden": false,
        "configurable": false,
        "type": "text"
    },
    {
        "id": "globalStatus",
        "index": 6,
        "isDisabled": false,
        "isSelected": false,
        "headerName": "Global Status",
        "dataField": "globalStage",
        "localizedKey": "atp.talent.globalStatus",
        "sortable": false,
        "sortByDefault": false,
        "configurable": true,
        "hidden": false,
        "type": "text"
    }
];
