/** -------------------------------------------------------------------------------------------------------------------
 * RadioButton
 * A component that renders a radio button with a given option
 *
 * @examples
 *
 *  ```jsx
 *    <RadioButton
 *      id="option1"
 *      label="Small viewport"
 *      onChanged={() => console.log("Viewport changed")}
 *    />
 * ```
 *
 *  @component RadioButton
 *  @import RadioButton
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


export default class RadioButton extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            id,
            label,
            className,
            tabIndex,
            disabled,
            onChange,
            ...rest
        } = this.props;

        return (
            <div className={classNames("radio-button-wrapper form-element", className)}>
                <input
                    id={id}
                    type="radio"
                    className="form-choice form-choice-radio"
                    tabIndex={tabIndex}
                    disabled={disabled}
                    onChange={onChange}
                    {...rest}
                />
                <label className="form-label" htmlFor={id}>
                    <span className="form-choice-indicator" />{label}
                </label>
            </div>
        );
    }
}


RadioButton.propTypes = {
    id: PropTypes.string,
    tabIndex: PropTypes.number,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

RadioButton.defaultProps = {
    tabIndex: 0
};

