/** ------------------------------------------------------------------------------------------------------------------
 * TextArea
 * A component that renders a text area with a placeholder text
 *
 * @examples
 *
 *  ```jsx
 *    <TextArea
 *      placeholder="Write something cool"
 *      value=""
 *      disabled={false}
 *      onChange={(value) => console.log("Value: ", value")}
 *    />
 * ```
 *
 *  @component TextArea
 *  @import TextArea
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getId from "../utils/get-id";

import "./text-area.scss";

export default class TextArea extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    _onChange = (event) => {
        const { value } = event.target;

        this.setState({
            value
        });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const {
            placeholder,
            className,
            disabled
        } = this.props;
        const classList = classNames("form-element text-area-wrapper", className, { disabled });
        const id = `textarea_${getId()}`;
        const { value } = this.state;
        const shouldShowPlaceholder = (!disabled && !value.length);

        return (
            <div className={classList}>
                <textarea
                    className="form-input form-input-textarea"
                    id={id}
                    aria-labelledby={`${id}_label`}
                    disabled={disabled}
                    onChange={this._onChange}
                    value={value}
                />
                {shouldShowPlaceholder && <span className="form-label" id={`${id}_label`}>{placeholder}</span>}
            </div>
        );
    }
}

TextArea.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

TextArea.defaultProps = {
    placeholder: "",
    value: "",
    className: "",
    onChange: () => {}
};
