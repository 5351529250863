/** -------------------------------------------------------------------------------------------------------------------
 * DatePicker
 * A component that renders a date picker component
 *
 * @examples
 *
 *  ```jsx
 *    <DatePicker
 *      dateTimeFormatter={DateTimeFormatter}
 *      onDateSelected={(date) => console.log("Date selected: ", date)}
 *    />
 * ```
 *
 *  @component DatePicker
 *  @import DatePicker
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Calendar from "./index";
import InputField from "../input-field";
import { fromDateComponents } from "../../../utils/dates";

import "./calendar.scss";


export default class DatePicker extends Component {

    constructor(props) {
        super(props);

        const currentDate = (props.date instanceof Date) ? props.date : new Date();
        const displayDate = (props.date instanceof Date);
        this._container = React.createRef();

        this.state = {
            currentDate,
            showingCalendar: false,
            displayDate
        };
    }

    _onFocus = () => {
        this.setState({
            showingCalendar: true
        });

        setTimeout(this._afterCalendarToggled, 0);
    };

    _onDateSelected = (event) => {
        const dateString = event.currentTarget.getAttribute("data-date");
        const components = dateString.split("-");
        const selectedDate = fromDateComponents(parseInt(components[0], 10), parseInt(components[1], 10) - 1,
            parseInt(components[2], 10));

        this.setState({
            currentDate: selectedDate,
            showingCalendar: false,
            displayDate: true
        });

        if (this.props.onDateSelected) {
            this.props.onDateSelected(selectedDate);
        }

        setTimeout(this._afterCalendarToggled, 0);
    };

    _toggleCalendar = () => {
        this.setState({
            showingCalendar: !this.state.showingCalendar
        });

        setTimeout(this._afterCalendarToggled, 0);
    };

    _afterCalendarToggled = () => {
        if (this.state.showingCalendar) {
            document.addEventListener("click", this._onDocumentClick);
        } else {
            document.removeEventListener("click", this._onDocumentClick);
        }
    }

    _onDocumentClick = (event) => {
        if (event.target.contains(this._container.current)) {
            this._toggleCalendar();
        }
    };

    render() {
        const {
            dateStrings,
            dateFormatter,
            className
        } = this.props;
        const { currentDate, showingCalendar, displayDate } = this.state;
        const dateString = displayDate ? dateFormatter.formatDate(currentDate, dateStrings.dateFormat.MEDIUM) : "";

        return (
            <div ref={this._container}
                className={classNames("date-picker-wrapper", className, { hovered: showingCalendar })}>
                <InputField
                    value={dateString}
                    showClearControl={false}
                    onFocus={this._onFocus}
                />
                <a href="javascript:void(0)"
                    className="calendar-toggle icon icon-calendar"
                    onClick={this._toggleCalendar} />
                {showingCalendar && <div className="calendar-container">
                    <Calendar
                        mode="compact"
                        date={currentDate}
                        dateStrings={dateStrings}
                        dateFormatter={dateFormatter}
                        onDayClicked={this._onDateSelected}
                    />
                </div>}
            </div>
        );
    }
}

DatePicker.propTypes = {
    date: PropTypes.object,
    dateStrings: PropTypes.object.isRequired,
    dateFormatter: PropTypes.object.isRequired,
    mode: PropTypes.string,
    className: PropTypes.string,
    onDateSelected: PropTypes.func
};

DatePicker.defaultProps = {
    date: new Date(),
    onDateSelected: () => {}
};
