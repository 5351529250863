export const talents = [
    {
        "id": 2122209,
        "talent": {
            "talentID": 2122209,
            "firstName": "Lisa",
            "lastName": "Berkeley"
        },
        "connectionId": 34423423,
        "connectionName": "Apple Referral",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "91423",
            "city": "sherman oaks"
        },
        "secondaryAddress": {
            "state": "California",
            "country": "USA",
            "zip": "91413",
            "city": "sunnyvale"
        },
        "preferredEmail": "lberkeley@apple.com",
        "preferredPhone": "323-799-5971",
        "educationDegrees": "BA, HS",
        "schools": "OTH, Howard University",
        "majorFields": "General Ed, Business",
        "employerNames": "Self-Employed, Google Inc",
        "jobTitles": "Remote IT support, AbstrAKT Games - Owner",
        "willingToRelocate": "Yes",
        "willingToTravel": "Yes",
        "languages": "English, French, Japanese",
        "talentLastModifiedDate": "2016-02-28T02:44:27.000Z",
        "lastModifiedDate": "2016-02-28T02:44:27.000Z",
        "globalStage": "Available",
        "globalStatus": "Sourcing",
        "talentType": "External, Apple Sourced",
        "connectionStatus": "Available",
        "connectionOwner": "Career Fair",
        "connectionSource": "Career Fair",
        "referralIndicator": "Yes",
        "previousStatus": "Available",
        "reason": "Yes",
        "reasonDetails": "Yes"
    },
    {
        "id": 21244209,
        "talent": {
            "talentID": 21244209,
            "firstName": "Beau",
            "lastName": "Morrow"
        },
        "connectionId": 23443423,
        "connectionName": "External",
        "connectionType": "Requisition",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "95403",
            "city": "San Francisco"
        },
        "secondaryAddress": {},
        "preferredEmail": "morrow@gmail.com",
        "preferredPhone": "413-022-9735",
        "educationDegrees": "MS, PHD",
        "schools": "UCLA, Howard University",
        "majorFields": "Software Engineering, Software",
        "employerNames": "Self-Employed, Yahoo Inc",
        "jobTitles": "JBS Systems - Owner, Programmer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, Spanish",
        "talentLastModifiedDate": "2017-01-28T02:44:27.000Z",
        "lastModifiedDate": "2017-01-28T02:44:27.000Z",
        "globalStage": "Pending",
        "globalStatus": "Approach",
        "talentType": "External, Apple Sourced",
        "connectionStatus": "Available",
        "connectionOwner": "Career Fair",
        "connectionSource": "Dice",
        "referralIndicator": "No",
        "previousStatus": "Not Available",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122550,
        "talent": {
            "talentID": 2122550,
            "firstName": "Oliver",
            "lastName": "Wai"
        },
        "connectionId": 9176383,
        "connectionName": "",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "Taxes",
            "country": "USA",
            "zip": "45729",
            "city": "Dallas"
        },
        "secondaryAddress": {
            "state": "California",
            "country": "USA",
            "zip": "95050",
            "city": "San Jose"
        },
        "preferredEmail": "wai@gmail.com",
        "preferredPhone": "401-888-1234",
        "educationDegrees": "Bachelors",
        "schools": "UCLA",
        "majorFields": "Chemical Engineering",
        "employerNames": "ABC Medical",
        "jobTitles": "Analyst",
        "willingToRelocate": "Yes",
        "willingToTravel": "No",
        "languages": "English, German",
        "talentLastModifiedDate": "2017-02-08T02:02:27.000Z",
        "lastModifiedDate": "2017-02-08T02:02:27.000Z",
        "globalStage": "Available",
        "globalStatus": "Approach",
        "talentType": "External",
        "connectionStatus": "Available",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122584,
        "talent": {
            "talentID": 2122584,
            "firstName": "Jason",
            "lastName": "Perna"
        },
        "connectionId": 1245908,
        "connectionName": "Jobsite",
        "connectionType": "Requisition",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "93212",
            "city": "Milpitas"
        },
        "secondaryAddress": {
            "state": "New York",
            "country": "USA",
            "zip": "82672",
            "city": "Yorkshire"
        },
        "preferredEmail": "jason@yahoo.com",
        "preferredPhone": "401-888-1234",
        "educationDegrees": "Bachelors, MS",
        "schools": "SJSU, NY State University",
        "majorFields": "Computer Engineering, Software Engineering",
        "employerNames": "Cisco Systems, Yahoo",
        "jobTitles": "Programmer, UI Manager",
        "willingToRelocate": "Yes",
        "willingToTravel": "Yes",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-02-08T02:02:27.000Z",
        "lastModifiedDate": "2017-02-08T02:02:27.000Z",
        "globalStage": "Available",
        "globalStatus": "Approach",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "",
        "connectionSource": "",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122529,
        "talent": {
            "talentID": 2122529,
            "firstName": "Ryan",
            "lastName": "Morhard"
        },
        "connectionId": 6483627,
        "connectionName": "Jobsite",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "92112",
            "city": "San Francisco"
        },
        "secondaryAddress": {},
        "preferredEmail": "morhard@gmail.com",
        "preferredPhone": "491-222-3232",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "User Experience",
        "employerNames": "Twitter",
        "jobTitles": "UX Manager",
        "willingToRelocate": "Yes",
        "willingToTravel": "No",
        "languages": "English",
        "talentLastModifiedDate": "2017-02-08T02:02:27.000Z",
        "lastModifiedDate": "2017-02-08T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Careers Inc",
        "connectionSource": "Job Fair",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122578,
        "talent": {
            "talentID": 2122578,
            "firstName": "Todd",
            "lastName": "Carter"
        },
        "connectionId": 9032123,
        "connectionName": "Jobsite",
        "connectionType": "Requisition",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "92112",
            "city": "San Francisco"
        },
        "secondaryAddress": {},
        "preferredEmail": "carter@gmail.com",
        "preferredPhone": "591-112-4321",
        "educationDegrees": "Bachelors",
        "schools": "UCLA",
        "majorFields": "Software Eng.",
        "employerNames": "Yahoo, HDS",
        "jobTitles": "Web Developer, UI Manager",
        "willingToRelocate": "Yes",
        "willingToTravel": "No",
        "languages": "English",
        "talentLastModifiedDate": "2017-02-08T02:02:27.000Z",
        "lastModifiedDate": "2017-02-08T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122624,
        "talent": {
            "talentID": 2122624,
            "firstName": "Noah",
            "lastName": "Galaviz"
        },
        "connectionId": 8937181,
        "connectionName": "Apple Jobsite",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "92112",
            "city": "Santa Clara"
        },
        "secondaryAddress": {},
        "preferredEmail": "noah@gmail.com",
        "preferredPhone": "408-891-3212",
        "educationDegrees": "Diploma",
        "schools": "Community College",
        "majorFields": "Software Development",
        "employerNames": "Google",
        "jobTitles": "Web Developer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English",
        "talentLastModifiedDate": "2017-02-08T02:02:27.000Z",
        "lastModifiedDate": "2017-02-08T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Dice",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122198,
        "talent": {
            "talentID": 2122198,
            "firstName": "William",
            "lastName": "Jackson"
        },
        "connectionId": 8937181,
        "connectionName": "Dice",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "Colorado",
            "country": "USA",
            "zip": "76262",
            "city": "Denvor"
        },
        "secondaryAddress": {},
        "preferredEmail": "jackson123@gmail.com",
        "preferredPhone": "810-764-3092",
        "educationDegrees": "Bachelors",
        "schools": "Colorado State University",
        "majorFields": "Software Engineering",
        "employerNames": "Intuit Sergicals",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "Yes",
        "willingToTravel": "Yes",
        "languages": "English, Spanish",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External, Apple Sourced",
        "connectionStatus": "Approach",
        "connectionOwner": "Monsters",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122189,
        "talent": {
            "talentID": 2122189,
            "firstName": "Janet",
            "lastName": "Polnoff"
        },
        "connectionId": 7822189,
        "connectionName": "Jobsite",
        "connectionType": "Requisition",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "92033",
            "city": "Bakersfield"
        },
        "secondaryAddress": {},
        "preferredEmail": "dean3@gmail.com",
        "preferredPhone": "210-214-3023",
        "educationDegrees": "Bachelors",
        "schools": "San Diego State University",
        "majorFields": "Software Management",
        "employerNames": "HDS",
        "jobTitles": "Manager",
        "willingToRelocate": "Yes",
        "willingToTravel": "No",
        "languages": "English-UK",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122676,
        "talent": {
            "talentID": 2122676,
            "firstName": "Denis",
            "lastName": "Haddad"
        },
        "connectionId": 7822189,
        "connectionName": "Careers Inc.",
        "connectionType": "Requisition",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "92033",
            "city": "Santa Monica"
        },
        "secondaryAddress": {},
        "preferredEmail": "denis2233@gmail.com",
        "preferredPhone": "202-404-2233",
        "educationDegrees": "Bachelors",
        "schools": "NY State University",
        "majorFields": "Software Management",
        "employerNames": "Cisco",
        "jobTitles": "Manager",
        "willingToRelocate": "Yes",
        "willingToTravel": "No",
        "languages": "English-UK",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Careers Inc",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122602,
        "talent": {
            "talentID": 2122602,
            "firstName": "Rob",
            "lastName": "Dull"
        },
        "connectionId": 8473848,
        "connectionName": "Jobsite",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "93333",
            "city": "San Francisco"
        },
        "secondaryAddress": {},
        "preferredEmail": "dull@gmail.com",
        "preferredPhone": "415-321-0001",
        "educationDegrees": "Bachelors",
        "schools": "Golden Gate University",
        "majorFields": "Software Engineering",
        "employerNames": "Facebook",
        "jobTitles": "Web Developer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approved",
        "connectionOwner": "Jobsite",
        "connectionSource": "",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122562,
        "talent": {
            "talentID": 2122562,
            "firstName": "Jennifer",
            "lastName": "Ryan"
        },
        "connectionId": 2434343,
        "connectionName": "Jobsite",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "93123",
            "city": "San Francisco"
        },
        "secondaryAddress": {},
        "preferredEmail": "ryan0202@gmail.com",
        "preferredPhone": "415-890-0202",
        "educationDegrees": "Bachelors",
        "schools": "Santa Clara University",
        "majorFields": "Software Engineering",
        "employerNames": "Facebook",
        "jobTitles": "User Researcher",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Unknown",
        "connectionSource": "Unknown",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122660,
        "talent": {
            "talentID": 2122660,
            "firstName": "David",
            "lastName": "Stewart-Rogers"
        },
        "connectionId": 2434343,
        "connectionName": "Jobsite",
        "connectionType": "Requisition",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "89382",
            "city": "Fremont"
        },
        "secondaryAddress": {},
        "preferredEmail": "david_stewart4343@gmail.com",
        "preferredPhone": "510-123-4343",
        "educationDegrees": "Bachelors, MS",
        "schools": "Stanford University",
        "majorFields": "Computer Science, Software Engineering",
        "employerNames": "Nvidia, Yahoo",
        "jobTitles": "User Researcher, UI Manager",
        "willingToRelocate": "Yes",
        "willingToTravel": "No",
        "languages": "English",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122701,
        "talent": {
            "talentID": 2122701,
            "firstName": "John",
            "lastName": "Miller"
        },
        "connectionId": 5683939,
        "connectionName": "Jobsite",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "89382",
            "city": "Fremont"
        },
        "secondaryAddress": {},
        "preferredEmail": "miller_john1111@gmail.com",
        "preferredPhone": "510-003-1111",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Paypal",
        "jobTitles": "UI Manager",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Unknown",
        "connectionSource": "Unknown",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122703,
        "talent": {
            "talentID": 2122703,
            "firstName": "Jacob",
            "lastName": "Luevano"
        },
        "connectionId": 5683939,
        "connectionName": "Jobsite",
        "connectionType": "Pipeline",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "89382",
            "city": "Fremont"
        },
        "secondaryAddress": {},
        "preferredEmail": "luevano@gmail.com",
        "preferredPhone": "510-003-1111",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Paypal",
        "jobTitles": "UI Manager",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Unknown",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122705,
        "talent": {
            "talentID": 2122705,
            "firstName": "George",
            "lastName": "Ortega"
        },
        "connectionId": 2122684,
        "connectionName": "Jobsite",
        "connectionType": "Requisition",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "89382",
            "city": "San Jose"
        },
        "secondaryAddress": {},
        "preferredEmail": "luevano@gmail.com",
        "preferredPhone": "510-003-1111",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Paypal",
        "jobTitles": "UI Manager",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approved",
        "connectionOwner": "External Jobsite",
        "connectionSource": "",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122684,
        "talent": {
            "talentID": 2122684,
            "firstName": "Chris",
            "lastName": "Kowalski"
        },
        "connectionId": 3243434,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Sunnyvale"
        },
        "secondaryAddress": {},
        "preferredEmail": "kowalski@gmail.com",
        "preferredPhone": "512-023-3321",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Paypal",
        "jobTitles": "UI Manager",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "Dice",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122212,
        "talent": {
            "talentID": 2122212,
            "firstName": "Nathaniel",
            "lastName": "Ashley"
        },
        "connectionId": 3243434,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Sunnyvale"
        },
        "secondaryAddress": {},
        "preferredEmail": "ashley@gmail.com",
        "preferredPhone": "212-013-8766",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Paypal",
        "jobTitles": "UI Manager",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "Unknown",
        "connectionSource": "Unknown",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122665,
        "talent": {
            "talentID": 2122665,
            "firstName": "Joe'l",
            "lastName": "Turner"
        },
        "connectionId": 3243434,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Fremont"
        },
        "secondaryAddress": {},
        "preferredEmail": "turner@gmail.com",
        "preferredPhone": "650-788-8976",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Paypal",
        "jobTitles": "UI Manager",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approved",
        "connectionOwner": "Unknown",
        "connectionSource": "Unknown",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122689,
        "talent": {
            "talentID": 2122689,
            "firstName": "Giuseppa (Pina )",
            "lastName": "Sicari"
        },
        "connectionId": 3243434,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "sicari@gmail.com",
        "preferredPhone": "650-123-8989",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Careers Inc",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122674,
        "talent": {
            "talentID": 2122674,
            "firstName": "Timothy",
            "lastName": "Remshak"
        },
        "connectionId": 2332323,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "remshak@gmail.com",
        "preferredPhone": "650-123-5633",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approved",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122193,
        "talent": {
            "talentID": 2122193,
            "firstName": "Matthew",
            "lastName": "Reda"
        },
        "connectionId": 2342343,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "reda@gmail.com",
        "preferredPhone": "650-113-1234",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Research",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122531,
        "talent": {
            "talentID": 2122531,
            "firstName": "Maged",
            "lastName": "Ragab"
        },
        "connectionId": 2342343,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "ragab@gmail.com",
        "preferredPhone": "650-113-1234",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Dice",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122564,
        "talent": {
            "talentID": 2122564,
            "firstName": "Chase",
            "lastName": "Engstrom"
        },
        "connectionId": 6543222,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "engstrom@gmail.com",
        "preferredPhone": "650-113-1234",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "External Jobsite",
        "connectionSource": "",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 2122682,
        "talent": {
            "talentID": 2122682,
            "firstName": "Stephen",
            "lastName": "McBrayer"
        },
        "connectionId": 8973637,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "mcbrayer@gmail.com",
        "preferredPhone": "650-113-1234",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Unknown",
        "connectionSource": "Unknown",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 1097360,
        "talent": {
            "talentID": 1097360,
            "firstName": "Badri",
            "lastName": "Bansal"
        },
        "connectionId": 1237373,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "bansal@gmail.com",
        "preferredPhone": "650-113-1234",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Unknown",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 1055430,
        "talent": {
            "talentID": 1055430,
            "firstName": "Arun",
            "lastName": "Seujattan"
        },
        "connectionId": 1237373,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "Modesto"
        },
        "secondaryAddress": {},
        "preferredEmail": "rockingdev@gmail.com",
        "preferredPhone": "650-113-1234",
        "educationDegrees": "Bachelors",
        "schools": "SJSU",
        "majorFields": "Software Engineering",
        "employerNames": "Yahoo",
        "jobTitles": "UX Desginer",
        "willingToRelocate": "No",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Research",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Pending",
        "connectionOwner": "Dice",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    },
    {
        "id": 1284634,
        "talent": {
            "talentID": 1284634,
            "firstName": "Ravi",
            "lastName": "Relan"
        },
        "connectionId": 9876536,
        "connectionName": "Jobsite",
        "connectionType": "External Sourced",
        "preferredAddress": {
            "state": "California",
            "country": "USA",
            "zip": "94537",
            "city": "San Francisco"
        },
        "secondaryAddress": {},
        "preferredEmail": "relan@gmail.com",
        "preferredPhone": "350-113-1334",
        "educationDegrees": "Bachelors",
        "schools": "UCLA",
        "majorFields": "Software Engineering",
        "employerNames": "Facebook",
        "jobTitles": "Web Developer",
        "willingToRelocate": "Yes",
        "willingToTravel": "No",
        "languages": "English, French",
        "talentLastModifiedDate": "2017-01-01T02:02:27.000Z",
        "lastModifiedDate": "2017-01-02T02:02:27.000Z",
        "globalStage": "Approach",
        "globalStatus": "Sourcing",
        "talentType": "External",
        "connectionStatus": "Approach",
        "connectionOwner": "Apple",
        "connectionSource": "Jobsite",
        "referralIndicator": "Yes",
        "previousStatus": "Pending",
        "reason": "No",
        "reasonDetails": ""
    }
];
