/** --------------------------------------------------------------------------------------------------------------------
 * NotificationPanel
 * A component that renders a sliding notification panel at the right side of the window
 *
 * @examples
 *
 *  ```jsx
 *    <NotificationPanel
 *      onModalClose={() => console.log("Notification panel closed")}>
 *          <header><h3>Notification Title</h3></header>
 *          <div className="notification-content">
 *          </div>
 *    </NotificationPanel>
 * ```
 *
 *  @component NotificationPanel
 *  @import NotificationPanel
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ModalDialog from "./index";


export default class NotificationPanel extends ModalDialog {

    constructor(props) {
        super(props);

        this._panelContainer = React.createRef();
    }

    _setRef = (container) => {
        this._panelContainer = container;
    }

    componentDidMount() {
        if (this._panelContainer && this._panelContainer.current) {
            setTimeout(() => {
                this._panelContainer.current.classList.add("revealed");
            }, 80);
        }
    }

    render() {
        const {
            children,
            className
        } = this.props;

        return (
            <div className={classNames("modal modal-standard notification-modal", className)}
                aria-hidden="false"
                role="dialog">
                <div className="backdrop" onClick={this._onDismiss} />
                <div className="content-wrapper">
                    <div className="content-padding" ref={this._panelContainer}>
                        <div className="modal-content">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NotificationPanel.propTypes = {
    ...ModalDialog.propTypes,
    children: PropTypes.node
};

NotificationPanel.defaultProps = ModalDialog.defaultProps;
