import * as types from "./auth.types";

const origin = "Web";

export const fetchAuthChallenge = () => (dispatch) => {

    return dispatch({
        types: [
            types.LOGIN_CHALLENGE_REQUEST,
            types.LOGIN_CHALLENGE_SUCCESS,
            types.LOGIN_CHALLENGE_FAILURE
        ],
        request: {
            endpoint: "/auth/challenge"
        }
    });
};


export const setAccountType = (accountType) => (dispatch) => {
    return dispatch({
        type: types.SET_ACCOUNT_TYPE,
        accountType
    });
};


export const login = (userId, accountType = "student", authorization) => (dispatch) => {

    const body = {
        authorization,
        userId,
        accountType,
        origin
    };

    return dispatch({
        types: [
            types.LOGIN_REQUEST,
            types.LOGIN_SUCCESS,
            types.LOGIN_FAILURE
        ],
        request: {
            endpoint: "/auth/login",
            method: "POST",
            body
        }
    });
};


export const activateUserSession = () => (dispatch) => {
    return dispatch({
        type: types.SESSION_ACTIVATED
    });
};

export const userSessionTimedOut = () => (dispatch) => {
    return dispatch({
        type: types.SESSION_TIMEDOUT
    });
};


export const logout = (userId, origin = "Web") => (dispatch) => {

    const body = {
        userId,
        origin
    };

    return dispatch({
        types: [
            types.LOGOUT_REQUEST,
            types.LOGOUT_SUCCESS,
            types.LOGOUT_FAILURE
        ],
        request: {
            endpoint: "/auth/logout",
            method: "POST",
            body
        }
    });
};


export const retrieveUserSession = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/session`;

    return dispatch({
        types: [
            types.USER_SESSION_REQUEST,
            types.USER_SESSION_SUCCESS,
            types.USER_SESSION_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const retrieveUserInfo = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/info`;

    return dispatch({
        types: [
            types.USER_INFO_REQUEST,
            types.USER_INFO_SUCCESS,
            types.USER_INFO_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};
