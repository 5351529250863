/** -------------------------------------------------------------------------------------------------------------------
 * Tab
 * A component that renders a tab in a tabbed view layout
 *
 * @examples
 *
 *  ```jsx
 *    <Tab
 *      title="HDR Video"
 *      index={0}
 *      active={true}
 *      onTabSelected={() => console.log("Tab selected")}
 *    />
 * ```
 *
 *  @component Tab
 *  @import Tab
 *  @returns {object} React functional component
 *
 *-------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


const Tab = ({
    title,
    className,
    disabled,
    active,
    index,
    onTabSelected
}) => {
    const onSelected = () => {
        if (disabled) {
            return;
        }

        if (onTabSelected) {
            onTabSelected(index);
        }
    };

    const classList = classNames("tabnav-item", className, {
        disabled,
        active
    });

    return (
        <li className={classList}>
            <a href="javascript:void(0)" className="tabnav-link" onClick={onSelected}>{title}</a>
        </li>
    );
};

Tab.propTypes = {
    title: PropTypes.any,
    className: PropTypes.string,
    index: PropTypes.number,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onTabSelected: PropTypes.func
};

Tab.defaultProps = {
    title: "",
    className: "",
    onTabSelected: () => {}
};

export default Tab;
