/** -------------------------------------------------------------------------------------------------------------------
 * Checkbox
 * A component that renders a checkbox and its related label
 *
 * @examples
 *
 *  ```jsx
 *    <Checkbox
 *      id="item-1"
 *      label="iPhone 10X"
 *      checked={true}
 *      disabled={false}
 *      highlightOnHover={false}
 *      onChange={() => console.log("Checkbox checked")}
 *    />
 * ```
 *
 *  @component Checkbox
 *  @import Checkbox
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getId from "../utils/get-id";

import "./checkbox.scss";

export default class Checkbox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id || `checkbox-${getId()}`,
            isChecked: !!(props.checked || props.defaultChecked)
        };
    }

    _handleRef = (input) => {
        this._input = input;
    }

    _handleChange = () => {
        const isChecked = this._input.checked;

        this.setState({
            isChecked
        });

        if (this.props.onChange) {
            this.props.onChange(this._input.id, isChecked);
        }
    }

    componentWillReceiveProps(nextProps) {
        const hasCheckedProp = (nextProps.hasOwnProperty("checked") || nextProps.hasOwnProperty("defaultChecked"));

        if (hasCheckedProp) {
            this.setState({
                isChecked: !!(nextProps.checked || nextProps.defaultChecked)
            });
        }
    }

    render() {
        const {
            label,
            className,
            disabled,
            checked, // eslint-disable-line no-unused-vars
            onChange, // eslint-disable-line no-unused-vars
            highlightOnHover,
            ...props
        } = this.props;
        const { id, isChecked } = this.state;

        return (
            <div className={classNames("form-element checkbox-wrapper", className, { disabled })}>
                <input
                    type="checkbox"
                    name={id}
                    id={id}
                    ref={this._handleRef}
                    disabled={disabled}
                    className="form-choice form-choice-checkbox"
                    checked={isChecked}
                    onChange={this._handleChange}
                    {...props}
                />
                <label className={classNames("form-label", { highlight: highlightOnHover })} htmlFor={id}>
                    <span className="form-choice-indicator" />
                    <span>{label}</span>
                </label>
            </div>
        );
    }
}

Checkbox.propTypes = {
    label: PropTypes.any,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool,
    highlightOnHover: PropTypes.bool,
    onChange: PropTypes.func
};

Checkbox.defaultProps = {
    tabIndex: 0,
    disabled: false,
    highlightOnHover: true,
    label: "",
    className: "",
    onChange: () => {}
};
