export const LOGIN_CHALLENGE_REQUEST = "LOGIN_CHALLENGE_REQUEST";
export const LOGIN_CHALLENGE_SUCCESS = "LOGIN_CHALLENGE_SUCCESS";
export const LOGIN_CHALLENGE_FAILURE = "LOGIN_CHALLENGE_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const USER_SESSION_REQUEST = "USER_SESSION_REQUEST";
export const USER_SESSION_SUCCESS = "USER_SESSION_SUCCESS";
export const USER_SESSION_FAILURE = "USER_SESSION_FAILURE";

export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAILURE = "USER_INFO_FAILURE";

export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";
export const SESSION_ACTIVATED = "SESSION_ACTIVATED";
export const SESSION_TIMEDOUT = "SESSION_TIMEDOUT";

export const USER_INFO_UPDATE_REQUEST = "USER_INFO_UPDATE_REQUEST";
export const USER_INFO_UPDATE_SUCCESS = "USER_INFO_UPDATE_SUCCESS";
export const USER_INFO_UPDATE_FAILURE = "USER_INFO_UPDATE_FAILURE";
