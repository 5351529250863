export default [
    {"id": "details", "title": "Details"},
    {"id": "software-category", "title": "Software Category"},
    {"id": "version", "title": "Version"},
    {"id": "build-number", "title": "Build Number"},
    {"id": "good-to-go-date", "title": "Good to Go Date"},
    {"id": "status", "title": "Status"},
    {"id": "partner-published", "title": "Partners Published"},
    {"id": "modified-by", "title": "Modified By"},
    {"id": "modification-date", "title": "Modification Date"},
    {"id": "created-by", "title": "Created By"},
    {"id": "created-date", "title": "Created Date"}
];
