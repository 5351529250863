/** -------------------------------------------------------------------------------------------------------------------
 * Toggle
 * A component that renders a checkbox and its related label
 *
 * @examples
 *
 *  ```jsx
 *    <Toggle
 *      id="item-1"
 *      label="Enabled"
 *      checked={true}
 *      disabled={false}
 *      onChange={() => console.log("Toggled")}
 *    />
 * ```
 *
 *  @component Toggle
 *  @import Toggle
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import Checkbox from "../checkbox";
import classNames from "classnames";

import "./toggle.scss";

export default class Toggle extends Checkbox {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            label,
            className,
            disabled,
            checked, // eslint-disable-line no-unused-vars
            onChange, // eslint-disable-line no-unused-vars
            highlightOnHover, // eslint-disable-line no-unused-vars
            ...props
        } = this.props;
        const { id, isChecked } = this.state;

        return (
            <div className={classNames("form-element form-choice-selector-wrapper", className, { disabled })}>
                <input
                    id={id}
                    className="visuallyhidden form-choice form-choice-selector"
                    type="checkbox"
                    ref={this._handleRef}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={this._handleChange}
                    {...props}
                />
                <label className="form-toggle form-label" htmlFor={id}>
                    <span>{label}</span>
                    <span className="form-toggle-indicator" />
                </label>
            </div>
        );
    }
}

Toggle.propTypes = Checkbox.PropTypes;
Toggle.defaultProps = Checkbox.defaultProps;

