/* global Classroom */
/** -------------------------------------------------------------------------------------------------------------------
 * Global Navigation
 * A component that renders the global navigation of the application. It provides the properties to the NavLlinks
 * and UserInfo child components.
 *
 * @examples
 *
 * ```jsx
 *  <GloalNav
 *    title="1UA"
 *    links=[
 *      { id: "home", title: "Home", url: "/"}
 *    ]
 *    user={
 *      firstName: "John", lastName: "Smith"
 *    }
 *  />
 * ```
 *
 *  @component GlobalNav
 *  @import GlobalNav
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

/* eslint-disable max-len */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NavLinks from "./nav-links";
import UserInfo from "./user-info";
import navLinks from "../../../store/data/nav-links";

import {
    activateUserSession,
    logout
} from "../../../store/reducers/auth/auth.actions";

import "./global-nav.scss";


export class GlobalNav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSession: false
        };
    }

    _onPanelActions = (action) => {
        switch (action) {
            case "sign-out":
                return this.props.logout(this.props.auth.userInfo.id);

            case "profile":
                this.props.history.push("/profile");
                break;

            default:
                if (this.props.onPanelActions) {
                    this.props.onPanelActions(action);
                }

                break;
        }
    };

    _processLinks() {
        const {
            auth: { userInfo: { accountType }},
            i18n: { getString }
        } = this.props;
        const isInstructor = accountType === "instructor";

        return navLinks.filter((link) => {
            return isInstructor ? (link.access === "all" || link.access === "instructor") :
                (link.access === "all");
        }).map((link) => {
            return {
                ...link,
                title: getString(link.localizedString)
            };
        });
    }

    componentDidMount() {
        this.props.activateUserSession();
        this.setState({
            activeSession: true
        });
    }

    componentDidUpdate(prevProps) {
        const hasSessionEnded = (prevProps.auth.activeSession === true && this.props.auth.activeSession === false);

        if (hasSessionEnded) {
            this.setState({
                activeSession: false
            });

            this.props.history.push(Classroom.contextPath);
        }
    }

    render() {
        const {
            title,
            auth: { userInfo },
            i18n
        } = this.props;
        const links = this._processLinks(navLinks);

        return (
            <section className="global-nav-wrapper">
                <input type="checkbox" id="global-nav-menu-state" className="global-nav-menu-state" />
                <nav className="main-nav" role="navigation" aria-label="Main Navigation">
                    <div className="nav-wrapper">
                        <div className="nav-content">
                            <div className="row">
                                <div className="column small-2 medium-3 small-show medium-hide action-wrapper menu-icon">
                                    <label className="menu-icon-label" htmlFor="global-nav-menu-state"
                                        aria-hidden="true">
                                        <span className="menu-icon-bread menu-icon-bread-top">
                                            <span className="menu-icon-bread-crust menu-icon-bread-crust-top" />
                                        </span>
                                        <span className="menu-icon-bread menu-icon-bread-bottom">
                                            <span className="menu-icon-bread-crust menu-icon-bread-crust-bottom" />
                                        </span>
                                    </label>
                                </div>
                                <div className="column small-12 medium-2 logo-wrapper">
                                    <h2 className="nav-title">
                                        <a href="/">
                                            <span className="localnav-title icon" />{title}
                                        </a>
                                    </h2>
                                </div>
                                <div className="column small-12 medium-10 nav-menu">

                                    <NavLinks links={links} />

                                    <UserInfo
                                        user={userInfo}
                                        i18n={i18n}
                                        onPanelActions={this._onPanelActions}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </section>
        );
    }
}

GlobalNav.propTypes = {
    auth: PropTypes.object,
    i18n: PropTypes.object,
    title: PropTypes.string,
    links: PropTypes.array.isRequired,
    onPanelActions: PropTypes.func,
    activateUserSession: PropTypes.func,
    logout: PropTypes.func,
    history: PropTypes.object
};

GlobalNav.defaultProps = {
    links: [],
    i18n: {
        getString: (string) => string
    },
    onPanelActions: () => {}
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth
});

const mapDispatchToProps = {
    activateUserSession,
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNav);
