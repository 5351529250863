/** ------------------------------------------------------------------------------------------------------------------
 * ContextualDialog
 * A component that renders a contextual dialog with relative to a DOM element
 *
 * @examples
 *
 *  ```jsx
 *    import ContextualDialog from './modal/contextual-dialog';
 *
 *    const contextuaDialog = new ContextualDialog({
 *      content: document.createElement("div"),
 *      relativeTo: contextualElement, // assuming this is an existing HTML element
 *      offsetLeft: 20,
 *      offsetTop: 10,
 *      modalWillShow: (modal) => console.log("Modal will show"),
 *      modalDidShow: (modal) => console.log("Modal did show"),
 *      modalWillHide: (modal) => console.log("Modal will hide"),
 *      modalDidHide: (modal) => console.log("Modal did hide")
 *    });
 *
 * ```
 *
 *  @class ContextualDialog
 *  @import ContextualDialog
 *  @returns {Function} a constructor function
 *
 *--------------------------------------------------------------------------------------------------------------------*/
import HTML         from "@akwaba/html";
import DOM          from "@akwaba/dom";
import Event        from "@akwaba/events";
import Extensions   from "@akwaba/object-extensions";

import "./modal.scss";


export default class ContextualDialog {

    constructor(options) {
        this.options = Object.assign({}, ContextualDialog.defaultOptions, options || { });
        this.element = HTML.createElement("div", { className: this.options.className });
        document.body.appendChild(this.element);

        HTML.setContent(this.element, ContextualDialog.template);
        HTML.hide(this.element);

        this.id = this.options.id || `contextualDialog-${Extensions.generateUUID().substring(0, 5)}`;
        [this.stem] = DOM.select("span.stem", this.element);

        [this.contentContainer] = DOM.select("div.content", this.element);
        this.registerEvents().show(this.options);
    }


    /**
     * Registers event handlers
     */
    registerEvents() {
        Event.add(document, "click", this.onDocumentClick);
        return this;
    }


    /** A  callback invoked when the document is clicked. It hides this contextual dialog.
     *
     * @param {Event} event     the click event on the document
     */
    onDocumentClick = (event) => {
        const isInsideModal = event.target === this.element || this.element.contains(event.target);

        if (!isInsideModal) {
            this.hide();
        }
    }


    /**
     * Sets the content of this contextual dialog, which should be a valid DOM element
     *
     * @param {Element} content         the content to display in this contextual panel
     */
    setContent(content) {

        if (content.nodeType !== HTML.nodeType.ELEMENT_NODE) {
            return this;
        }

        content.id = content.id || `dialogContent-${Extensions.generateUUID().substring(0, 5)}`;
        this.contentContainer.appendChild(content);
        this.currentContent = content;
        HTML.show(content);

        return this;
    }


    /**
     * Displays this contextual dialo in the UI. The options object contains the DOM element relative to which the
     * panel should be displayed. It also adjusts the position of the panel and its stem if it overflows the width
     * or heightvof the document
     *
     * @param {Object} options       an object that contains the display options (relative element, offsets, etc)
     */
    show(options) {
        this.options.modalWillShow(this);

        const relativeElement = this.options.relativeTo;
        const isValidElement = (relativeElement && relativeElement.nodeType === HTML.nodeType.ELEMENT_NODE);

        if (!isValidElement) {
            return;
        }

        this.setContent(options.content);
        HTML.removeClassName(this.element, "modal-small");
        HTML.addClassName(this.element, "modal-normal");
        HTML.addClassName(this.element, `theme-${this.options.theme}`);
        HTML.addClassName(this.element, (this.options.size === "small") ? "modal-small" : "modal-normal");

        const position = this.getPosition(relativeElement);
        const cssStyle = {
            position: "absolute",
            left: `${position.left}px`,
            top: `${position.top}px`
        };

        this.stem.className = position.stemClass;
        HTML.setStyle(this.element, cssStyle);
        HTML.show(this.element);

        this.visible = true;
        this.options.modalDidShow(this);
    }


    /**
     * Returns an object that contains the absolute positions at which to show the contextual panel, in the coordinate
     * system of the document
     *
     * @param {Element} targetElement       the DOM element relative to which to compute the position of the modal panel
     * @return {Object} an object that contains the absolute position at which to show the contextual panel
     */
    getPosition(targetElement) {
        const adjustments = {
            left: this.options.offsetLeft,
            top: this.options.offsetTop,
            stemHeight: 20,
            stemClass: "stem left"
        };

        const viewport = DOM.viewport();

        const dimensions = DOM.dimensions(targetElement);
        const offsets = DOM.cumulativeOffset(targetElement);
        const { width: modalWidth, height: modalHeight } = DOM.dimensions(this.element);

        let posX = offsets.left + dimensions.width + adjustments.left;
        let posY = offsets.top + Math.floor((dimensions.height - modalHeight) / 2) + adjustments.top;

        if (posX + modalWidth > viewport.width) {
            posX = offsets.left - modalWidth - adjustments.left;
            adjustments.stemClass = "stem right";
        }

        const hasVerticalOverflow = (posY + modalHeight + adjustments.top > viewport.height + viewport.offsetTop);

        if (hasVerticalOverflow) {
            posX = posX - Math.floor(modalWidth / 2) - adjustments.left * 1.5;
            posY = offsets.top - modalHeight + adjustments.top * 1.5;
            adjustments.stemClass = "stem middle bottom";
        }

        return {
            left: posX,
            top: posY,
            stemClass: adjustments.stemClass
        };
    }


    /**
     * Hides this contextual dialog
     */
    hide() {
        this.options.modalWillHide(this);
        HTML.hide(this.element);
        this.destroy();
        this.options.modalDidHide(this);
    }


    /**
     * Destroys this contextual dialog and cleans up its resources
     */
    destroy() {
        Event.remove(document, "click", this.onDocumentClick);
        document.body.removeChild(this.element);
        this.element = null;
        this.contentContainer = null;
    }

}

ContextualDialog.defaultOptions = {
    className: "modal-dialog contextual-dialog",
    hideOnClick: document.body,
    content: document.createElement("div"),
    relativeTo: document.body,
    modalWillShow: () => {},
    modalDidShow: () => {},
    modalWillHide: () => {},
    modalDidHide: () => {},
    enableDestroyAction: false,
    showOKButton: true,
    showCancelButton: true,
    offsetLeft: 20,
    offsetTop: 0,
    theme: "light"
};

ContextualDialog.template = `
    <div class="content"></div>
    <span class="stem left">&nbsp;</span>
`;
