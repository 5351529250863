/** ------------------------------------------------------------------------------------------------------------------
 * Tooltip
 * A component that renders a tooltip with a label and content
 *
 * @examples
 *
 *  ```jsx
 *    <Tooltip
 *      label="Quick tip"
 *      content="Quick tip content"
 *      position="right"
 *    />
 * ```
 *
 *  @component Tooltip
 *  @import Tooltip
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getId from "../utils/get-id";

import "./tooltip.scss";

const Tooltip = ({ label, position, content, className }) => {
    const id = `tooltip-${getId()}`;
    const classList = classNames("form-icons-wrapper form-icons-focusable form-label", className);

    return (
        <div className={classList}>
            {label}
            <div className="form-tooltip-wrapper">
                <a href="javascript:void(0)" role="button" className="form-icons form-icons-info15"
                    aria-describedby={id}></a>
                <div id={id} aria-hidden="true" className={`form-tooltip form-tooltip-${position}`} role="tooltip">
                    <p className="form-tooltip-content">{content}</p>
                </div>
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    position: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.string,
    className: PropTypes.string
};

Tooltip.defaultProps = {
    position: "right",
    label: "Quick tip",
    content: "Quick tip content",
    className: ""
};

export default Tooltip;
