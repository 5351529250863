/** -------------------------------------------------------------------------------------------------------------------
 * Button
 * A component that renders a button in different modes and styles
 *
 * @examples
 *
 *  ```jsx
 *    <Button
 *      type="button"
 *      title="Click me"
 *      secondary={false}
 *      compact={true}
 *      disabled={false}
 *      onClick={() => console.log("Button clicked")}
 *    />
 * ```
 *
 *  @component Button
 *  @import Button
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


const Button = ({
    title,
    className,
    type,
    secondary,
    compact,
    disabled,
    onClick
}) => {
    const classList = classNames("button", className, {
        "button-secondary": secondary,
        "button-compact": compact
    });

    return (
        <button
            type={type}
            className={classList}
            disabled={disabled}
            onClick={onClick}>
            {title}
        </button>
    );

};

Button.propTypes = {
    type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    compact: PropTypes.bool,
    onClick: PropTypes.func
};

Button.defaultProps = {
    type: "button",
    title: "",
    className: "",
    disabled: false,
    compact: false,
    secondary: false,
    onClick: () => {}
};

export default Button;
