/** -------------------------------------------------------------------------------------------------------------------
 * DurationSelector
 * A component that renders a list of end times based on a start date and minute interval, and allows for
 * the selection of a given one
 *
 * @examples
 *
 *  ```jsx
 *    <DurationSelector
 *      dateTimeFormatter={dateTimeFormatter}
 *      minuteInterval={15}
 *      maxInterval={8}
 *      onDurationSelected={(durationInfo) => console.log("Duration selected: ", durationInfo)}
 *    />
 * ```
 *
 *  @component DurationSelector
 *  @import DurationSelector
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Numbers from "../../../utils/numbers";
import { format } from "../../../utils/strings";

import "./duration-selector.scss";


export default class DurationSelector extends Component {

    constructor(props) {
        super(props);

        const startDate = (props.startDate instanceof Date) ? props.startDate : new Date();
        this._container = React.createRef();

        this.state = {
            startDate,
            durationIntervals: this._getDurationIntervals(),
            endTimeIntervals: [],
            selectedDuration: null,
            showingPanel: false
        };

        this.durationContainer = React.createRef();
    }

    _toggleDurationPanel = () => {
        this.setState({
            showingPanel: !this.state.showingPanel
        });

        setTimeout(this._afterCalendarToggled, 0);
    };

    _afterCalendarToggled = () => {
        if (this.state.showingPanel) {
            document.addEventListener("click", this._onDocumentClick);
        } else {
            document.removeEventListener("click", this._onDocumentClick);
        }
    }

    _onDocumentClick = (event) => {
        if (event.target.contains(this._container.current)) {
            this._toggleDurationPanel();
        }
    };

    _onDurationSelected = (event) => {
        const endTimeIntervals = this._computeEndTimeIntervals();
        let selectedDuration = endTimeIntervals.find((info) => info.id === event.target.getAttribute("data-id"));
        selectedDuration = selectedDuration || endTimeIntervals[0];

        this.setState({
            selectedDuration
        });

        if (this.props.onDurationSelected) {
            this.props.onDurationSelected(selectedDuration.value);
        }

        this._toggleDurationPanel();
    };

    _findEndTimeById(id) {
        const durationInfo = this.state.endTimeIntervals.find((info) => info.id === id);
        return durationInfo || this.state.endTimeIntervals[0];
    }

    _getDurationIntervals() {
        const { minuteInterval, maxInterval } = this.props;
        const intervals = [];
        Numbers.times(maxInterval, (number, index) => {
            intervals.push((index + 1) * minuteInterval);
        });

        return intervals;
    }

    _computeEndTimeIntervals() {
        const { templates, dateStrings, dateFormatter } = this.props;
        const { startDate, durationIntervals } = this.state;
        const timeIntervals = [];
        const startTimeInMillis = startDate.getTime();

        durationIntervals.forEach((interval) => {
            const template = (interval < 60) ? templates.MINUTES : (interval === 60) ? templates.HOUR : templates.HOURS;
            const timeInterval = (interval < 60) ? interval : (interval / 60).toFixed(2);
            const referenceDate = startTimeInMillis + interval * 60 * 1000;
            const formattedEndTime = dateFormatter.formatDate(new Date(referenceDate), dateStrings.timeFormat.MEDIUM);
            const duration = (interval === 60) ? format(template) : format(template, [timeInterval]);

            const timeInfo = {
                id: `duration-${interval}`,
                time: formattedEndTime,
                title: formattedEndTime,
                value: interval,
                duration
            };
            timeIntervals.push(timeInfo);
        });

        return timeIntervals;
    }

    _renderEndTimes() {
        const { selectedDuration } = this.state;
        const endTimeIntervals = this._computeEndTimeIntervals();

        return endTimeIntervals.map((timeInfo) => {
            const selected = (selectedDuration && timeInfo.id === selectedDuration.id);

            return (
                <li key={timeInfo.id} className={classNames({ selected })}>
                    <a href="javascript:void(0)"
                        data-id={timeInfo.id}
                        data-time={timeInfo.formattedEndTime}>
                        {timeInfo.time}<em data-id={timeInfo.id}>{timeInfo.duration}</em>
                        {selected && <i className="icon icon-checkmark" />}
                    </a>
                </li>
            );
        });
    }

    componentDidMount() {
        const { duration } = this.props;
        const endTimeIntervals = this._computeEndTimeIntervals();
        let [selectedDuration] = endTimeIntervals;

        if (duration && duration > 0) {
            selectedDuration = endTimeIntervals.find((interval) => interval.value === duration);
        }

        this.setState({
            endTimeIntervals,
            selectedDuration
        });

        if (this.props.onDurationSelected) {
            this.props.onDurationSelected(selectedDuration.value);
        }
    }

    render() {
        const { className } = this.props;
        const {
            showingPanel,
            selectedDuration
        } = this.state;

        const endTimeIntervals = this._computeEndTimeIntervals();
        let currentDuration = endTimeIntervals.find((interval) => {
            return (selectedDuration && interval.id === selectedDuration.id);
        });
        currentDuration = currentDuration || endTimeIntervals[0];

        return (
            <div ref={this._container}
                className={classNames("duration-selector", className)}>
                <p className="form-input form-input-text end-time" onClick={this._toggleDurationPanel}>
                    <span>{currentDuration.time}</span>
                    <i className="icon icon-chevron-down" />
                </p>
                {showingPanel && <ul ref={this.durationContainer}
                    className="contextual-selector time-intervals"
                    onClick={this._onDurationSelected}>
                    {this._renderEndTimes(endTimeIntervals, currentDuration)}
                </ul>}
            </div>
        );
    }
}

const templates = {
    MINUTES: "{0} minutes",
    HOUR: "1 hour",
    HOURS: "{0} hours"
};

DurationSelector.propTypes = {
    dateStrings: PropTypes.object.isRequired,
    dateFormatter: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
    duration: PropTypes.number,
    minuteInterval: PropTypes.number,
    maxInterval: PropTypes.number,
    className: PropTypes.string,
    templates: PropTypes.object,
    onDurationSelected: PropTypes.func
};

DurationSelector.defaultProps = {
    startDate: new Date(),
    minuteInterval: 15,
    maxInterval: 8,
    templates,
    onDurationSelected: () => {}
};
