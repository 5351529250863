/** -------------------------------------------------------------------------------------------------------------------
 * Loader
 * A component that displays an inline spinner
 *
 * @examples
 *
 *  ```jsx
 *    <Loader />
 * ```
 *
 *  @component Loader
 *  @import Loader
 *  @returns {object} React functional object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";

import "./spinner.scss";


export const Loader = () => (
    <div className="loader inline" />
);
