/** -------------------------------------------------------------------------------------------------------------------
 * CalendarNav
 * A component that renders a set of navigation links for a calendar
 *
 * @examples
 *
 *  ```jsx
 *    <CalendarNav
 *      onCalendarNavigation={(target) => console.log("On calendar navigation")}
 *    />
 * ```
 *
 *  @component CalendarNav
 *  @import { CalendarNav }
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";

import "./calendar.scss";


const i18n = {
    previous: "Previous",
    today: "Today",
    next: "Next"
};


export const CalendarNav = ({
    theme,
    onCalendarNavigation,
    i18n
}) => {
    const arrowLeftClassName = (theme === "light") ? "icon-arrow-left-white" : "icon-arrow-left";
    const arrowRightClassName = (theme === "light") ? "icon-arrow-right-white" : "icon-arrow-right";

    const _onCalendarNavigation = (event) => {
        if (onCalendarNavigation) {
            onCalendarNavigation(event);
        }
    };

    return (
        <ul className="calendar-nav">
            <li className="previous day">
                <a href="javascript:void(0)"
                    title={i18n.previous}
                    className="previous"
                    rel="previous"
                    onClick={_onCalendarNavigation}>
                    <i className={`icon ${arrowLeftClassName}`} />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" className="today" title={i18n.today} rel="today"
                    onClick={_onCalendarNavigation}>{i18n.today}</a>
            </li>
            <li className="next day">
                <a href="javascript:void(0)"
                    title={i18n.next}
                    className="next-day"
                    rel="next"
                    onClick={_onCalendarNavigation}>
                    <i className={`icon ${arrowRightClassName}`} />
                </a>
            </li>
        </ul>
    );
};

CalendarNav.propTypes = {
    theme: PropTypes.string,
    onCalendarNavigation: PropTypes.func,
    i18n: PropTypes.object
};

CalendarNav.defaultProps = {
    i18n,
    onCalendarNavigation: () => {}
};
