/** ------------------------------------------------------------------------------------------------------------------
 * WeekDay
 * A component that renders a calendar's week day in month view mode
 *
 * @examples
 *
 *  ```jsx
 *    <WeekDay
 *      date={date}
 *      weekDay={weekDay}
 *      currentDate={currentDate}
 *      events={events}
 *    />
 * ```
 *
 *  @component WeekDay
 *  @import { WeekDay }
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


export const WeekDay = ({
    date,
    today,
    isCurrentMonth,
    dateString,
    events,
    activeEvent,
    onDaySelected,
    onDayDoubleClicked
}) => {
    const isToday = (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear());
    const isWeekend = (date.getDay() === 0 || date.getDay() === 6);

    const generateEventsForDay = () => {
        return !(events.length) ? <ol className="events"></ol> : (
            <React.Fragment>
                <ol data-type="event-container" className="events">
                    {events.map((event, index) => (
                        <li data-id={event.id}
                            key={`event-${index}`}
                            className={classNames({"highlighted": (activeEvent && event.id === activeEvent.id)})}>
                            <em data-type="color-code" className={classNames("circle-code filled", event.type)} />
                            <span data-type="event-name" className="ellipsis">{event.name}</span>
                            <span data-type="event-time" className="date-time">{event.startTime}</span>
                        </li>
                    ))}
                </ol>
                <a href="#" className="more" style={{display: "none"}}>more</a>
            </React.Fragment>
        );
    };

    const _onDoubleClick = (event) => {
        const node = event.target;

        if (node.getAttribute("data-type") === "weekday") {
            onDayDoubleClicked(node.getAttribute("data-date"));
        }
    };

    return (
        <td
            data-type="weekday"
            data-date={dateString}
            key={date.getDate()}
            className={classNames({
                "today": !!(isToday && isCurrentMonth),
                "weekend": isWeekend,
                "other-month": !isCurrentMonth
            })}
            onClick={onDaySelected}
            onDoubleClick={_onDoubleClick}>
            <span>{date.getDate()}</span>
            {generateEventsForDay()}
        </td>
    );
};

WeekDay.propTypes = {
    today: PropTypes.object,
    date: PropTypes.object,
    isCurrentMonth: PropTypes.bool,
    dateString: PropTypes.string,
    events: PropTypes.array,
    activeEvent: PropTypes.object,
    onDaySelected: PropTypes.func,
    onDayDoubleClicked: PropTypes.func
};

WeekDay.defaultProps = {
    events: [],
    isCurrentMonth: true,
    onDaySelected: () => {},
    onDayDoubleClicked: () => {}
};
