export const seconds = (number) => number * 1000;

export const minutes = (number) => 60 * seconds(number);

export const hours = (number) => 60 * minutes(number);

export const days = (number) => 24 * hours(number);

export const weeks = (number) => 7 * days(number);

export const months = (number) => 30 * days(number);

export const years = (number) => 365.25 * days(number);

export const toDateString = (number) => {
    const date = new Date();
    date.setTime(number);
    return date;
};

export const times = (number, iterator, context) => {
    if (number < 0) {
        return number;
    }

    for (let i = 0; i < number; i++) {
        iterator.call(context, number, i);
    }

    return number;
};

export const toPaddedString = (number, length, radix = 10) => {
    const string = number.toString(radix);
    let result = "";

    times(length - string.length, () => {
        result += "0";
    });

    return `${result}${string}`;
};

export const formatFileSize = (size, roundSize = false) => {
    const sizeUnits = ["Bytes", "KB", "MB", "GB"];
    let i;

    for (i = 0; size > 1024; i++) {
        size = roundSize ? Math.floor(size / 1024) : (size / 1024).toFixed(2);
    }

    return `${size.toLocaleString()} ${sizeUnits[i]}`;
};

export const formatDurationInMinSecs = (duration) => {
    if (!duration || (duration === 0)) {
        return "--:--";
    }

    const minutes = Math.floor(((duration / 60) % 60));
    const seconds = duration % 60;

    const mins = (minutes < 10) ? `0${minutes}` : `${minutes}`;
    const secs = (seconds < 10) ? `0${seconds}` : `${seconds}`;
    return `${mins}:${secs}`;
};
