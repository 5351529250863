/** -------------------------------------------------------------------------------------------------------------------
 * Calendar
 * A component that renders a calendar in the default month view
 *
 * @examples
 *
 *  ```jsx
 *    <Calendar
 *      dateTimeFormatter={dateTimeFormatter}
 *      events={eventList}
 *      eventRenderer={eventRenderer}
 *      eventProcessor={getEventsForDay}
 *      onDateChanged={(date) => {console.log("Date changed: ", date)}
 *    />
 * ```
 *
 *  @component Calendar
 *  @import Calendar
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { WeekDay } from "./week-day";
import { CalendarNav } from "./calendar-nav";
import * as dateUtils from "./date-utils";

import "./calendar.scss";


/**
 * Renders the HTML elements for each event in list view. This is the default implementation and must be overridden.
 *
 * @param date          the date for which to render the event elements
 * @param events        the events for which to generate the elements
 * @return the HTML elements for the given events
 */
const eventRenderer = () => (
    <li className="events no-items">
        <p className="info">No events scheduled</p>
    </li>
);


export default class Calendar extends Component {

    constructor(props) {
        super(props);

        const weekArray = (props.date instanceof Date) ? dateUtils.getWeekArray(props.date) : [];
        const currentDate = (props.date instanceof Date) ? props.date : new Date();

        this.state = {
            initialDate: currentDate,
            currentDate,
            today: new Date(),
            weekArray
        };
    }

    _getEventsForDay(day) {
        if (this.props.mode === "compact") {
            return [];
        }

        const {
            events,
            dateFormatter,
            dateStrings
        } = this.props;
        const dateString = dateFormatter.formatDate(day, dateStrings.dateFormat.SHORT_DASHED);

        return events.filter((event) => {
            const formattedDate = dateFormatter.formatDate(new Date(Date.parse(event.startDate)),
                dateStrings.dateFormat.SHORT_DASHED);
            return formattedDate === dateString;
        });
    }

    _generateCalendarDays() {
        const {
            dateFormatter,
            dateStrings,
            activeEvent,
            onDayClicked,
            onDayDoubleClicked
        } = this.props;
        const {
            weekArray,
            currentDate,
            today
        } = this.state;
        const weekdayNodes = [];

        const FIRST_WEEK = 0;
        const LAST_WEEK = weekArray.length - 1;

        weekArray.forEach((week, index) => {
            const isFirstWeek = index === FIRST_WEEK;
            const isLastWeek = index === LAST_WEEK;

            const weekDays = week.map((weekDay, j) => {
                /** 7 and 21 have been proven to handle most use cases of month overlap */
                const isPreviousMonth = (isFirstWeek && weekDay.getDate() > 7);
                const isNextMonth = (isLastWeek && weekDay.getDate() < 21);
                const formattedDateString = dateFormatter.formatDate(weekDay, dateStrings.dateFormat.SHORT_DASHED);

                return (
                    <WeekDay
                        key={j}
                        today={today}
                        date={weekDay}
                        currentDate={currentDate}
                        isCurrentMonth={!(isPreviousMonth || isNextMonth)}
                        dateString={formattedDateString}
                        events={this._getEventsForDay(weekDay)}
                        activeEvent={activeEvent}
                        onDaySelected={onDayClicked}
                        onDayDoubleClicked={onDayDoubleClicked}
                    />
                );
            });

            weekdayNodes.push(
                <tr key={index}>
                    {weekDays}
                </tr>
            );
        });

        return (
            <React.Fragment>
                {weekdayNodes}
            </React.Fragment>
        );
    }

    _drawCalendar() {
        const {
            dateFormatter,
            dateStrings,
            mode
        } = this.props;
        const { currentDate } = this.state;
        const calendarDays = this._generateCalendarDays();
        const columnHeaders = [];

        for (let i = 0; i < 7; i++) {
            columnHeaders.push(
                <th key={i}>
                    {(mode === "compact") ? dateStrings.shortWeekDays[i] : dateStrings.mediumWeekDays[i]}
                </th>
            );
        }

        const template = (
            <table className={classNames("calendar calendar-month", `calendar-${mode}`)}>
                <caption>{dateFormatter.formatDate(currentDate, dateStrings.dateFormat.MONTH_YEAR)}</caption>
                <thead>
                    <tr>
                        {columnHeaders}
                    </tr>
                </thead>
                <tbody>
                    {calendarDays}
                </tbody>
            </table>
        );

        return template;
    }

    _gotoPreviousMonth = () => {
        const { onDateChanged } = this.props;
        let { currentDate } = this.state;
        currentDate.setDate(1);
        currentDate = dateUtils.addMonths(this.state.currentDate, -1);

        this.setState({
            currentDate,
            weekArray: dateUtils.getWeekArray(currentDate)
        }, () => {
            if (onDateChanged) {
                onDateChanged(currentDate);
            }
        });
    };

    _gotoNextMonth = () => {
        const { onDateChanged } = this.props;
        let { currentDate } = this.state;

        const daysToEndOfMonth = dateUtils.getDaysInMonth(currentDate) - currentDate.getDate();
        currentDate = dateUtils.addDays(currentDate, daysToEndOfMonth + 1);

        this.setState({
            currentDate,
            weekArray: dateUtils.getWeekArray(currentDate)
        }, () => {
            if (onDateChanged) {
                onDateChanged(currentDate);
            }
        });
    };

    _showToday = () => {
        const { onDateChanged } = this.props;
        const { today } = this.state;

        this.setState({
            currentDate: dateUtils.cloneDate(today),
            weekArray: dateUtils.getWeekArray(today)
        }, () => {
            if (onDateChanged) {
                onDateChanged(today);
            }
        });
    };

    _renderCompactNavigation() {
        return (
            <nav className="nav-container">
                <ul className="calendar-nav">
                    <li className="previous day">
                        <a href="javascript:void(0)" className="previous"
                            rel="previous" onClick={this._onCalendarNavigation}>
                            <i className="icon icon-arrow-left" />
                        </a>
                    </li>
                    <li className="next day">
                        <a href="javascript:void(0)" className="next"
                            rel="next" onClick={this._onCalendarNavigation}>
                            <i className="icon icon-arrow-right" />
                        </a>
                    </li>
                </ul>
            </nav>
        );
    }

    _onCalendarNavigation = (event) => {
        switch (event.currentTarget.rel) {

            case "previous":
                return this._gotoPreviousMonth();

            case "next":
                return this._gotoNextMonth();

            default:
                return this._showToday();
        }
    };

    render() {
        const {
            mode,
            className,
            dateStrings: {
                relativeDate: { TODAY }
            }
        } = this.props;

        const navigationStrings = {
            previous: "",
            today: TODAY,
            tomorrow: ""
        };

        return (
            <div className={classNames("calendar calendar-default", mode, className)}>
                <header className="navigation">
                    {(mode === "compact") ? this._renderCompactNavigation() :
                        <CalendarNav
                            theme="light"
                            i18n={navigationStrings}
                            onCalendarNavigation={this._onCalendarNavigation}
                        />}
                </header>
                {this._drawCalendar()}
            </div>
        );
    }
}

Calendar.propTypes = {
    dateStrings: PropTypes.object.isRequired,
    dateFormatter: PropTypes.object.isRequired,
    date: PropTypes.object,
    className: PropTypes.string,
    type: PropTypes.string,
    mode: PropTypes.string,
    events: PropTypes.array,
    activeEvent: PropTypes.object,
    eventRenderer: PropTypes.func,
    onDateChanged: PropTypes.func,
    onDayClicked: PropTypes.func,
    onDayDoubleClicked: PropTypes.func
};

Calendar.defaultProps = {
    date: new Date(),
    type: "table",
    mode: "standard",
    events: [],
    eventRenderer,
    onDateChanged: () => {},
    onDayClicked: () => {},
    onDayDoubleClicked: () => {}
};
