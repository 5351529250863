/** -------------------------------------------------------------------------------------------------------------------
 * User Info
 * A component that renders the user info in the header section
 *
 * @examples
 *
 * ```jsx
 *  <UserInfo
 *    user={
 *      firstName: "John", lastName: "Smith"
 *    }
 *  />
 * ```
 *
 *  @component UserInfo
 *  @import UserInfo
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export class UserInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showingPanel: false
        };

        this._container = React.createRef();
    }

    _togglePanel = () => {
        const shouldShow = !this.state.showingPanel;

        this.setState({
            showingPanel: shouldShow
        });

        if (shouldShow) {
            document.addEventListener("click", this._onDocumentClick);
        } else {
            document.removeEventListener("click", this._onDocumentClick);
        }
    };

    _onPanelActions = (event) => {
        const action = event.currentTarget.getAttribute("data-action");

        if (this.props.onPanelActions) {
            this.props.onPanelActions(action);
        }

        this._togglePanel();
    };

    _onDocumentClick = () => {
        this._togglePanel();
    };

    render() {
        const {
            user: { firstName, lastName, gender },
            i18n,
            onNotificationAction
        } = this.props;
        const { showingPanel } = this.state;

        return (
            <div ref={this._container} className="small-12 medium-3 user-info-wrapper">
                <div className="user-info">
                    <a className="icon-navigation-notification-bell"
                        data-action="notifications"
                        onClick={onNotificationAction} />
                    {firstName && <a href="javascript:void(0)" className="name"
                        data-action="preferences" onClick={this._togglePanel}>
                        {firstName} {lastName}
                        <span className="icon icon-chevrondown" />
                    </a>}
                </div>
                {showingPanel && <ul className="user-preferences" role="menu" aria-labelledby="preferences-trigger">
                    <li role="menuitem">
                        <a href="javascript:void(0);" className="menu-item"
                            data-action="profile"
                            onClick={this._onPanelActions}>
                            <span className={classNames("icon icon-female", {
                                "icon-male": gender === "male"
                            })} />
                            <span>{i18n.getString("title.profile")}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="javascript:void(0);" className="menu-item"
                            data-action="sign-out"
                            onClick={this._onPanelActions}>
                            <span className="icon icon-room" />
                            <span>{i18n.getString("login.logout")}</span>
                        </a>
                    </li>
                </ul>}
            </div>
        );
    }
}

UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
    i18n: PropTypes.object,
    onPanelActions: PropTypes.func,
    hasNotifications: PropTypes.bool,
    onNotificationAction: PropTypes.func
};

UserInfo.defaultProps = {
    user: {},
    onPanelActions: () => {},
    onNotificationAction: () => {}
};

export default UserInfo;
