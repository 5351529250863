import React, { Component } from "react";
import Events from "@akwaba/events";

import { format } from "../utils/strings";
import * as Dates from "../utils/dates";
import { LOCALE_CHANGED_EVENT } from "../store/reducers/application/context.types";


const generateDateBundles = (messages) => {

    const relativeDate = {
        TODAY: messages["date.today"],
        YESTERDAY: messages["date.yesterday"],
        TOMORROW: messages["date.tomorrow"],
        HOUR_FORMAT: "%H:%M",
        DATE_FORMAT: "%b",
        YEAR_FORMAT: "%Y"
    };

    const dateFormat = {
        SHORT: messages["date.dateFormatShort"],
        SHORT_DASHED: messages["date.dateFormatShortDashed"],
        MEDIUM: messages["date.dateFormatMedium"],
        LONG: messages["date.dateFormatLong"],
        STANDARD: messages["date.dateFormatStandard"],
        MONTH_DAY: messages["date.calendarMonthDay"],
        MONTH_YEAR: messages["date.calendarMonthYear"],
        MONTH_DAY_YEAR: messages["date.dateFormat"],
        ISO: "%Y-%m-%DT%H:%M:%S",
        UTC: "%Y-%m-%DT%H:%M:%SZ"
    };

    const timeFormat = {
        SHORT: messages["date.timeFormatShort"],
        MEDIUM: messages["date.timeFormatMedium"],
        HOURS: messages["date.timeFormatHours"],
        DATE_TIME_SHORT: messages["date.timeFormatShort"]
    };

    return {
        shortWeekDays: messages["date.weekdaysShort"].split(" "),
        mediumWeekDays: messages["date.weekdaysMedium"].split(" "),
        weekDays: messages["date.weekdays"].split(" "),
        shortMonths: messages["date.monthsMedium"].split(" "),
        months: messages["date.months"].split(" "),
        relativeDate,
        dateFormat,
        timeFormat
    };

};


let userLocale;

export const InjectIntl = (WrappedComponent, locale = "en") => {
    userLocale = locale;

    class IntlComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                userLocale
            };

            Events.add(document.body, LOCALE_CHANGED_EVENT, this._onLanguageChanged);
        }

        _onLanguageChanged = (data) => {
            userLocale = data.model.locale;
            this._loadResourceBundles();
        };

        _loadResourceBundles() {
            const resourceBundle = (!userLocale || userLocale.toLowerCase() === "en") ?
                "messages" :
                `messages-${userLocale}`;

            const messages = require(`./messages/${resourceBundle}.json`);
            Dates.addLanguagePack(userLocale, generateDateBundles(messages));

            this.setState({
                i18n: {
                    messages,
                    languagePack: Dates.getLanguagePack()
                }
            });
        }

        componentWillMount() {
            this._loadResourceBundles();
        }

        componentWillUnmount() {
            Events.remove(document.body, LOCALE_CHANGED_EVENT, this._onLanguageChanged);
        }

        getString = (string) => {
            const { messages } = this.state.i18n;
            return (messages && messages.hasOwnProperty(string)) ? messages[string] : string;
        };

        formatString = (string, replacement) => {
            const { messages } = this.state.i18n;
            return (messages && messages.hasOwnProperty(string)) ? format(messages[string], replacement) :
                string;
        };

        formatDate = (date, format) => {
            return Dates.format(date, format);
        };

        render() {
            const { i18n } = this.state;

            if (!i18n) {
                return (<div />);
            }

            i18n.format = this.formatString;
            i18n.getString = this.getString;
            i18n.formatDate = this.formatDate;
            i18n.formatTime = this.formatDate;

            return (
                <WrappedComponent
                    {...this.props}
                    i18n={i18n}
                />
            );
        }
    }

    IntlComponent.displayName = `WithIntl(${WrappedComponent.displayName || WrappedComponent.name})`;

    return IntlComponent;
};

