import React from "react";
import Button from "../shared/button";
import RadioButtonGroup from "../shared/radio-button";
import { TabContent } from "../shared/tabbed-view/tab-content";

const options = [
    {label: "Small viewport", value: "small"},
    {label: "Medium viewport", value: "medium"},
    {label: "Large viewport", value: "large"},
    {label: "Extra large viewport", value: "extra-large", disabled: true},
    {label: "Extra extra large viewport", value: "extra-extra-large"}
];

/* eslint-disable no-console */
export const ButtonView = () => (
    <TabContent key="buttons" title="Buttons" className="tab-content">
        <div className="module-section">
            <p>
                <Button title="Primary" />
                <span style={{marginRight: "12px"}} />
                <Button title="Secondary" secondary={true} />
                <span style={{marginRight: "12px"}} />
                <Button title="Primary Disabled" disabled={true} />
                <span style={{marginRight: "12px"}} />
                <Button title="Secondary Disabled" secondary={true} disabled={true} />
            </p>
            <p>
                <Button title="Primary" compact={true} />
                <span style={{marginRight: "12px"}} />
                <Button title="Secondary" compact={true} secondary={true} />
                <span style={{marginRight: "12px"}} />
                <Button title="Primary Disabled" compact={true} disabled={true} />
                <span style={{marginRight: "12px"}} />
                <Button title="Secondary Disabled" compact={true} secondary={true} disabled={true} />
            </p>
        </div>
        <div className="module-section section-medium">
            <RadioButtonGroup
                options={options}
                defaultValue="small"
                onChange={(value) => console.log("Radio button changed: ", value)}
            />
        </div>
    </TabContent>
);
