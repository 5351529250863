/** -------------------------------------------------------------------------------------------------------------------
 * Selector
 * A component that renders a select node with its respective options
 *
 * @examples
 *
 *  ```jsx
 *    <Selector
 *      label="Show"
 *      options={[
 *          {id: "latest", title: "Latest Activity" },
 *          {id: "all", title: "All Activities" }
 *      ]}
 *      onChange={(event) => console.log("onChange: ", event.target.value)}
 *    />
 * ```
 *
 *  @component Selector
 *  @import Selector
 *  @returns {object} React object
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getId from "../utils/get-id";

import "./selector.scss";

const Selector = ({
    label,
    options,
    className,
    disabled,
    onChange
}) => {
    const id = `selector-${getId()}`;
    const classList = classNames("form-cell select-wrapper", className, { disabled });

    const containsOptionGroups = !!(!Array.isArray(options) && Object.keys(options).length);
    const selectedOption = containsOptionGroups ? null : options.find((option) => option.selected) || options[0];

    const generateOption = (option) => {
        const optionId = option.id || `option-${getId()}`;
        return (
            <option
                key={optionId}
                id={optionId}
                value={optionId}
            >{option.title}</option>
        );
    };

    const generateOptions = (options) => {
        return options.map((option) => generateOption(option));
    };

    const generateOptionGroups = (options) => {
        return Object.keys(options).map((group, index) => {
            const optionNodes = options[group].map((option) => generateOption(option));

            return (
                <optgroup key={index} label={group}>
                    {optionNodes}
                </optgroup>
            );
        });
    };

    return (
        <div className={classList}>
            {label && <label htmlFor={id}>{label}</label>}
            <div className="form-element">
                <select className="form-dropdown"
                    id={id}
                    defaultValue={selectedOption ? selectedOption.id : null}
                    disabled={disabled}
                    onChange={onChange}>
                    { containsOptionGroups ? generateOptionGroups(options) : generateOptions(options) }
                </select>
                <span className="icon icon-chevron-down" aria-hidden="true" />
            </div>
        </div>
    );
};

Selector.propTypes = {
    label: PropTypes.string,
    options: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

Selector.defaultProps = {
    label: "",
    className: "",
    options: [],
    onChange: () => {}
};

export default Selector;
