const SCRIPT_REGEX = /<script[^>]*>([\S\s]*?)<\/script>/gim;
const TAG_REGEX = /<\/?[^>]+>/gi;
const TEMPLATE_FORMAT = /(\{[^}]+\})/gm;

export const trim = (string) => {
    return string.replace(/^\s+/, "").replace(/\s+$/, "");
};

export const truncate = (string, length = 30, truncation = "...") => {
    return (string.length > length) ? `${string.slice(0, length - truncation.length)}${truncation}` : string;
};

export const toCamelCase = (string) => {
    return string.replace(/-\D/g, (match) => match.charAt(1).toUpperCase());
};

export const capitalize = (string) => {
    return string.replace(/\b[a-z]/g, (match) => match.toUpperCase());
};

export const escapeHTML = (string) => {
    return string.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
};

export const isEmpty = (string) => (trim(string).length === 0);

export const contains = (string, pattern) => (string.indexOf(pattern) !== -1);

export const startsWith = (string, pattern) => (string.indexOf(pattern) === 0);

export const endsWith = (string, pattern) => {
    const delta = string.length - pattern.length;
    return (delta > 0 && string.lastIndexOf(pattern) === delta);
};

export const removeScripts = (string) => {
    return string ? string.replace(SCRIPT_REGEX, "") : "";
};

export const removeTags = (string) => {
    return string ? string.replace(TAG_REGEX, "") : "";
};

export const removeQuotes = (string) => {
    return string ? string.replace(/"/g, "") : "";
};

export const format = (string, replacement) => {
    return string.replace(TEMPLATE_FORMAT, (match) => {
        const key = match.substring(1, match.length - 1);
        return replacement[key] || match;
    });
};

export const toUnicode = (text) => {
    let result = "";

    for (let i = 0, len = text.length; i < len; i++) {
        let codePoint = text.charCodeAt(i).toString(16).toUpperCase();

        while (codePoint.length < 4) {
            codePoint = `0${codePoint}`;
        }

        codePoint = `\\u${codePoint}`;
        result += codePoint;
    }

    return result;
};

