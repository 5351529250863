/** -------------------------------------------------------------------------------------------------------------------
 * TableBody
 * A component that renders the body content for a given table
 *
 * @examples
 *
 *  ```jsx
 *    <TableBody
 *      item={item}
 *      columns={columns}
 *      extraColumns={extraColumns}
 *      showSelectors={true}
 *      showFavorite={true}
 *      showContextualMenu={true}
 *      cellRenderer={<cell_render_function>}
 *      cellDetailsRenderer={<cell_details_renderer_function>}
 *      onClick={(event) => console.log("On row clicked")}
 *      onSelectorChecked={(event) => console.log("On selector checked")}
 *      contextualIcon="chevrondown"
 *      activeContextualIcon="chevronup"
 *    />
 * ```
 *
 *  @component TableBody
 *  @import TableBody
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Checkbox from "../checkbox";
import getId from "../utils/get-id";

export const TableBody = ({
    item,
    columns,
    extraColumns,
    showSelectors,
    showFavorite,
    showContextualMenu,
    cellRenderer,
    cellDetailsRenderer,
    onClick,
    onSelectorChecked,
    contextualIcon,
    activeContextualIcon
}) => {
    const itemId = item.id || item.itemId || `item-${getId()}`;

    return (
        <React.Fragment>
            <tr key={itemId}
                className={classNames({ selected: item.selected, expanded: item.expanded })}
                onClick={onClick}
                role="row">
                {showSelectors &&
                    <td className="selector">
                        <Checkbox id={`checkbox-${itemId}`}
                            highlightOnHover={false}
                            checked={item.selected}
                            onChange={onSelectorChecked}
                            className="item-selector"
                        />
                        {showFavorite &&
                            <button
                                data-action="markAsFavorite"
                                className={classNames("favorite", {"is-favorite": item.isFavorite})}
                            />
                        }
                        {!!item.color && <span className={classNames("banner", item.color)} />}
                    </td>
                }
                {columns.map((column, i) => (
                    <td key={`cell-${i}`}>
                        {cellRenderer(item, column.dataField)}
                    </td>
                ))}
                {showContextualMenu &&
                    <td className="contextual-action">
                        <button
                            id={`button-${itemId}`}
                            data-action="toggleDetails"
                            className={classNames("icon", contextualIcon, {
                                [activeContextualIcon]: item.expanded
                            })}
                        />
                    </td>
                }
            </tr>
            <tr className="details-row"
                onClick={onClick}>
                <td colSpan={columns.length + extraColumns}>
                    <div className="item-detail-wrapper">
                        {cellDetailsRenderer(item)}
                    </div>
                    {item.color && <span className={classNames("banner", item.color)} />}
                </td>
            </tr>
        </React.Fragment>
    );
};

TableBody.propTypes = {
    item: PropTypes.object,
    columns: PropTypes.array.isRequired,
    extraColumns: PropTypes.number,
    showSelectors: PropTypes.bool,
    showFavorite: PropTypes.bool,
    showContextualMenu: PropTypes.bool,
    cellRenderer: PropTypes.func.isRequired,
    cellDetailsRenderer: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onSelectorChecked: PropTypes.func,
    contextualIcon: PropTypes.string,
    activeContextualIcon: PropTypes.string
};

TableBody.defaultProps = {
    showSelectors: true,
    showContextualMenu: true,
    onClick: () => {},
    onSelectorChecked: () => {},
    contextualIcon: "chevron-down",
    activeContextualIcon: "chevron-up"
};
