import React, { PureComponent } from "react";
import classNames from "classnames";
import Calendar from "../shared/calendar";
import CalendarListView from "../shared/calendar/calendar-list-view";
import SegmentedControl from "../shared/segmented-control";
import { TabContent } from "../shared/tabbed-view/tab-content";
import events from "../../store/data/events";
import * as DateTimeFormatter from "../../utils/dates";


const eventRenderer = (date, events) => {
    return (
        <React.Fragment>
            {events.map((event) => (
                <li key={event.id}>
                    <em className={classNames("circle-code", event.type)}>&nbsp;</em>
                    <h4 className="ellipsis">{event.name}</h4>
                    <p className="info">
                        {event.summary}
                        <a href="#" className="learn-more" title="Learn more">
                            <span className="icon icon-after icon-chevronrightcircle">Learn more</span>
                        </a>
                    </p>
                    <span className="date-time">9:00 AM - 6:00 PM</span>
                </li>
            ))
            }
        </React.Fragment>
    );
};

const getEventsForDay = (date) => {
    switch (date.getDate()) {

        case 7:
            return events.slice(0, 3);

        case 15:
            return events.slice(3, 5);

        case 19:
            return events.slice(6);

        case 20:
            return events.slice(5, 7);

        case 21:
        case 28:
            return events.slice(0, 1);

        default:
            return [];

    }
};

export default class CalendarView extends PureComponent {

    constructor(props) {
        super(props);

        const segments = [
            {id: "month-view", title: "Month view" },
            {id: "list-view", title: "List view" },
        ];

        this.state = {
            segments,
            selectedView: "month-view",
            currentDate: new Date()
        };
    }

    _onSegmentActivated = (segment) => {
        const selectedIndex = (segment.id === "month-view") ? 0 : 1;
        const segments = this.state.segments.map((segment, index) => {
            segment.selected = index === selectedIndex;
            return segment;
        });

        this.setState({
            selectedView: segment.id,
            segments
        });
    }

    render() {
        const { segments, selectedView } = this.state;
        const { currentDate } = this.state;

        return (
            <TabContent key="calendars" title="Calendar" className="tab-content">
                <div className="module-section">
                    <div className="view-selector">
                        <SegmentedControl
                            segments={segments}
                            onSegmentActivated={this._onSegmentActivated}
                        />
                    </div>
                    { (selectedView === "month-view") ?
                        <Calendar
                            date={currentDate}
                            dateStrings={DateTimeFormatter.getLanguagePack()}
                            dateFormatter={DateTimeFormatter}
                            events={events}
                            eventRenderer={eventRenderer}
                            eventProcessor={getEventsForDay}
                        /> :
                        <CalendarListView
                            dateStrings={DateTimeFormatter.getLanguagePack()}
                            dateFormatter={DateTimeFormatter}
                            events={events}
                            eventRenderer={eventRenderer}
                            eventProcessor={getEventsForDay}
                            showDetailsView={true}
                        />
                    }
                </div>
            </TabContent>
        );
    }
}
