export const addDays = (date, days) => {
    const newDate = cloneDate(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
};

export const addMonths = (date, months) => {
    const newDate = cloneDate(date);
    newDate.setMonth(date.getMonth() + months);
    return newDate;
};

export const addYears = (date, years) => {
    const newDate = cloneDate(date);
    newDate.setFullYear(date.getFullYear() + years);
    return newDate;
};

export const cloneDate = (date) => {
    return new Date(date.getTime());
};

export const cloneAsDate = (date) => {
    const clonedDate = cloneDate(date);
    clonedDate.setHours(0, 0, 0, 0);
    return clonedDate;
};

export const getDaysInMonth = (date) => {
    const resultDate = getFirstDayOfMonth(date);

    resultDate.setMonth(resultDate.getMonth() + 1);
    resultDate.setDate(resultDate.getDate() - 1);

    return resultDate.getDate();
};

export const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getFirstDayOfWeek = () => {
    const now = new Date();
    return new Date(now.setDate(now.getDate() - now.getDay()));
};

export const normalizeDate = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
};

export const getWeekArray = (date, addPadding = true) => {
    const daysInMonth = getDaysInMonth(date);
    const weekArray = [];

    for (let i = 1; i <= daysInMonth; i++) {
        weekArray.push(new Date(date.getFullYear(), date.getMonth(), i));
    }

    if (!addPadding) {
        return weekArray;
    }

    const firstDayOfMonth = getFirstDayOfMonth(date);
    const firstDayOfWeek = getFirstDayOfWeek(date);

    if (firstDayOfMonth.getDay() > firstDayOfWeek.getDay()) {
        const dayDelta = firstDayOfMonth.getDay() - firstDayOfWeek.getDay();

        for (let delta = 1; delta <= dayDelta; delta++) {
            weekArray.unshift(addDays(firstDayOfMonth, -delta));
        }
    }

    const lastDayOfMonth = weekArray[weekArray.length - 1];

    if (lastDayOfMonth.getDay() < 6) {
        const dayDelta = 6 - lastDayOfMonth.getDay();

        for (let delta = 1; delta <= dayDelta; delta++) {
            weekArray.push(addDays(lastDayOfMonth, delta));
        }
    }

    return weekArray.reduce((result, current, index) => {
        if (index % 7 === 0) {
            const target = [];
            target.push(current);
            result.push(target);
        } else {
            result[result.length - 1].push(current);
        }

        return result;
    }, []);
};

export const isBeforeDate = (date1, date2) => {
    const d1 = cloneAsDate(date1);
    const d2 = cloneAsDate(date2);

    return (d1.getTime() < d2.getTime());
};

export const isAfterDate = (date1, date2) => {
    const d1 = cloneAsDate(date1);
    const d2 = cloneAsDate(date2);

    return (d1.getTime() > d2.getTime());
};

export const isBetweenDates = (date, startDate, endDate) => {
    return (!(isBeforeDate(date, startDate)) &&
            !(isAfterDate(date, endDate)));
};

export const defaultUtils = {
    addDays,
    addMonths,
    addYears,
    getFirstDayOfWeek,
    getFirstDayOfMonth,
    getWeekArray,
    isBeforeDate,
    isAfterDate,
    isBetweenDates,
    normalizeDate,
    cloneDate,
    cloneAsDate
};

