/** ------------------------------------------------------------------------------------------------------------------
 * Calendar
 * A component that renders a calendar in list view
 *
 * @examples
 *
 *  ```jsx
 *    <CalendarListView
 *      dateTimeFormatter={dateTimeFormatter}
 *      events={eventList}
 *      eventRenderer={eventRenderer}
 *      eventProcessor={getEventsForDay}
 *      onDateChanged={(date) => {console.log("Date changed: ", date)}
 *    />
 * ```
 *
 *  @component CalendarListView
 *  @import CalendarListView
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Calendar from "./index";
import { CalendarNav } from "./calendar-nav";
import * as dateUtils from "./date-utils";
import "./calendar.scss";


export default class CalendarListView extends Calendar {

    constructor(props) {
        super(props);

        this.state = {
            currentDate: (props.date instanceof Date) ? props.date : new Date(),
            weekArray: this._generateWeekArray(props.date)
        };
    }

    _generateWeekArray(date) {
        return (date instanceof Date) ? dateUtils.getWeekArray(date, false) : [];
    }

    _renderEventsForDay(date) {
        return this.props.eventRenderer(date, this._getEventsForDay(date));
    }

    _generateCalendarDays() {
        const {
            dateStrings,
            dateFormatter,
        } = this.props;
        const { weekArray } = this.state;
        const weekdayNodes = weekArray.map((weekDay, index) => {
            const weekdayString = dateFormatter.formatDate(weekDay, dateStrings.dateFormat.LONG);
            const dayId = dateFormatter.formatDate(weekDay, dateStrings.dateFormat.SHORT_DASHED);

            return (
                <li data-date={dayId}
                    key={`weekday-${index + 1}`}>
                    <h5 className="block">
                        <span className="weekday">{weekdayString}</span>
                    </h5>
                    <ol data-type="event-container" className="events">
                        {this._renderEventsForDay(weekDay)}
                    </ol>
                </li>
            );
        });

        return (
            <React.Fragment>
                {weekdayNodes}
            </React.Fragment>
        );
    }

    _generateDayDetailsView() {
        const {
            dateStrings,
            dateFormatter,
        } = this.props;
        const { currentDate } = this.state;
        const elementNodes = [];

        for (let i = 0; i < 24; i++) {
            currentDate.setHours(i);
            const formattedHours = (i === 12) ? "Noon" : dateFormatter.formatDate(currentDate,
                dateStrings.timeFormat.HOURS);

            const container = (
                <li key={`hour-${i}`}
                    className="event-hour-container">
                    <span className="time">{formattedHours}</span>
                    <div className="morning" />
                    <div className="afternoon" />
                </li>
            );
            elementNodes.push(container);
        }

        return (
            <React.Fragment>
                {elementNodes}
            </React.Fragment>
        );
    }

    _gotoPreviousMonth = () => {
        const { onDateChanged } = this.props;
        let { currentDate } = this.state;
        currentDate.setDate(1);
        currentDate = dateUtils.addMonths(currentDate, -1);

        this.setState({
            currentDate,
            weekArray: this._generateWeekArray(currentDate)
        }, () => {
            if (onDateChanged) {
                onDateChanged(currentDate);
            }
        });
    };

    _gotoNextMonth = () => {
        const { onDateChanged } = this.props;
        let { currentDate } = this.state;

        const daysToEndOfMonth = dateUtils.getDaysInMonth(currentDate) - currentDate.getDate();
        currentDate = dateUtils.addDays(currentDate, daysToEndOfMonth + 1);

        this.setState({
            currentDate,
            weekArray: this._generateWeekArray(currentDate)
        }, () => {
            if (onDateChanged) {
                onDateChanged(currentDate);
            }
        });
    };

    render() {
        const {
            dateStrings,
            dateFormatter,
            showDetailsView
        } = this.props;
        const { currentDate } = this.state;
        const formattedDate = dateFormatter.formatDate(currentDate, dateStrings.dateFormat.MONTH_YEAR);

        return (
            <div className={classNames("calendar-view view-list")}>
                <h3 className="current-month ellipsis">
                    {formattedDate}
                    <CalendarNav
                        theme="light"
                        onCalendarNavigation={this._onCalendarNavigation}
                    />
                </h3>
                <ul className="block weekdays" onClick={this._onCalendarDayClicked}>
                    {this._generateCalendarDays()}
                </ul>
                {showDetailsView && <div className="day-detail-container">
                    <ul className="day-details">
                        {this._generateDayDetailsView()}
                    </ul>
                </div>}
            </div>
        );
    }
}

CalendarListView.propTypes = {
    ...Calendar.propTypes,
    showDetailsView: PropTypes.bool
};

CalendarListView.defaultProps = {
    ...Calendar.defaultProps,
    type: "list"
};
