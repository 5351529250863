/** -------------------------------------------------------------------------------------------------------------------
 * DashboardModule
 * A component that renders a module on a dashboard page, with a header, a content, and an action sections
 *
 * @examples
 *
 *  ```jsx
 *    <DashboardModule
 *          className="home-module"
 *          header={
 *              <React.Fragment>
 *                  <h3 className="column large-11">Dashboard</h3>
 *              </React.Fragment>
 *          }
 *          content={
 *              <React.Fragment>
 *                  <div className="content">
 *                      <p>Content goes here</p>
 *                  </div>
 *              </React.Fragment>
 *          }
 *          actions={
 *              <React.Fragment>
 *                  <a href="#">View more <span className="icon icon-after icon-chevronrightcircle"/></a>
 *              </React.Fragment>
 *         }
 *    />
 * ```
 *
 *  @component DashboardModule
 *  @import DashboardModule
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./dashboard-module.scss";

const DashboardModule = ({
    header,
    content,
    actions,
    className
}) => {
    const classList = classNames("block-module dashboard-module-wrapper", className);

    return (
        <div className={classList}>
            <header className="row">
                {header}
            </header>
            <div className="module-content-wrapper">
                <div className="content">
                    {content}
                </div>
                {actions && <div className="actions">
                    {actions}
                </div>}
            </div>
        </div>
    );
};

DashboardModule.propTypes = {
    header: PropTypes.node,
    content: PropTypes.node,
    actions: PropTypes.node,
    className: PropTypes.string
};

DashboardModule.defaultProps = {
    className: ""
};

export default DashboardModule;
