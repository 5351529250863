/** -------------------------------------------------------------------------------------------------------------------
 * ModalDialog
 * A component that renders given its child components in a modal dialog
 *
 * @examples
 *
 *  ```jsx
 *    <ModalSheet
 *      modalDidOpen={() => console.log("Modal did open")}
 *      modalWillClose={() => console.log("Modal will close")}
 *      onModalClose={() => console.log("Modal did close")}>
 *      <div>Content goes here</div>
 *    </ModalDialog>
 *    />
 * ```
 *
 *  @component ModalDialog
 *  @import ModalDialog
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "../button";
import ModalDialog from "./index";

const icons = {
    info: "checkcircle",
    status: "checkcircle",
    success: "checkalt15",
    warning: "unvalidatedalt19",
    error: "unvalidatedalt19"
};


export default class ModalSheet extends ModalDialog {

    _onOK = () => {
        if (this.props.onOK) {
            this.props.onOK();
        }
    };

    render() {
        const {
            title,
            type,
            mode,
            okLabel,
            cancelLabel,
            className,
            children,
            showCancelButton
        } = this.props;

        const iconClassName = classNames(`icon form-icons form-icons-${icons[type]}`, {
            "icon-info": ["info", "status"].includes(type)
        });

        return (
            <div className={classNames("modal modal-sheet", `modal-${mode}`, type, className)}
                aria-hidden="false"
                role="dialog">
                <div className="backdrop" onClick={this._onDismiss} />
                <div className="content-wrapper">
                    <div className="content-padding">
                        <div className="modal-content">
                            <header className="modal-header">
                                <h3>{title}</h3>
                            </header>
                            <div className="row item-content">
                                <p className="column icon-wrapper">
                                    <span className={iconClassName} />
                                </p>
                                <div className="column info">
                                    {children}
                                </div>
                            </div>
                            <div className="actions">
                                {showCancelButton && <Button
                                    title={cancelLabel}
                                    secondary={true}
                                    compact={true}
                                    onClick={this._onDismiss}
                                />}
                                <Button
                                    title={okLabel}
                                    compact={true}
                                    onClick={this._onOK}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ModalSheet.propTypes = {
    ...ModalDialog.propTypes,
    type: PropTypes.oneOf(["info", "status", "error", "success", "warning"]).isRequired,
    title: PropTypes.string,
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    showCancelButton: PropTypes.bool,
    onOK: PropTypes.func
};

ModalSheet.defaultProps = {
    ...ModalDialog.defaultProps,
    mode: "compact",
    type: "info",
    okLabel: "OK",
    cancelLabel: "Cancel",
    showCancelButton: true,
    onOK: () => {}
};
