/** -------------------------------------------------------------------------------------------------------------------
 * TabbedView
 * A component that renders a tabbed view with either a horizontal or vertical layout
 *
 * @examples
 *
 *  ```jsx
 *    <TabbedView
 *      layout="horizontal"
 *      tabs={[
 *          {id: "hdr-video", title: "HDR Video"},
 *          {id: "burst-mode", title: "Burst Mode"}
 *      ]}
 *      tabContent={[
 *          <TabContent key="hdr-content" title="HDR Content" className="tab-content">
 *              <h3>HDR Video</h3>
 *          </TabContent>,
 *          <TabContent key="burst-mode" title="Burst Mode" className="tab-content">
 *              <h3>Burst mode</h3>
 *          </TabContent>
 *      ]}
 *      onTabSelected={(index) => console.log("Tab selected at index ", index)}
 *    />
 * ```
 *
 *  @component TabbedView
 *  @import TabbedView
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Tab from "./tab";

import "./tabbed-view.scss";


export default class TabbedView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: this._getSelectedTabIndexFrom(props.tabs)
        };
    }

    _onTabSelected = (index) => {
        let { selectedIndex } = this.state;

        if (selectedIndex !== index) {
            selectedIndex = index;

            this.setState({
                selectedIndex
            });

            if (this.props.onTabSelected) {
                this.props.onTabSelected(selectedIndex);
            }
        }
    }

    _getSelectedTabIndexFrom(tabList) {
        const selectedTab = tabList.find((tab) => !!tab.selected);
        return selectedTab ? tabList.indexOf(selectedTab) : 0;
    }

    _generateTabFrom(itemList) {
        return itemList.map((content, index) => (
            <Tab
                key={index}
                index={index}
                title={content.title || content.props.title}
                active={index === this.state.selectedIndex}
                onTabSelected={this._onTabSelected}
            />
        ));
    }

    _getActiveTabContent() {
        const { selectedIndex } = this.state;
        const { tabContent } = this.props;

        const activeContent = tabContent.find((content) => tabContent.indexOf(content) === selectedIndex);
        return activeContent ? React.cloneElement(activeContent) : null;
    }

    componentWillReceiveProps(nextProps) {
        const { selectedIndex } = this.state;
        const newSelectedIndex = nextProps.tabs ? this._getSelectedTabIndexFrom(nextProps.tabs) : null;

        if (newSelectedIndex && selectedIndex !== newSelectedIndex) {
            this.setState({
                selectedIndex: newSelectedIndex
            });
        }
    }

    render() {
        const {
            className,
            layout,
            tabs,
            tabContent
        } = this.props;
        const classList = classNames("tabnav", layout, className);
        const tabList = (!tabs.length) ? this._generateTabFrom(tabContent) : this._generateTabFrom(tabs);

        return (
            <div className={classList}>
                <ul className="tabnav-items">
                    {tabList}
                </ul>
                <div className="tabnav-content-wrapper">
                    {this._getActiveTabContent()}
                </div>
            </div>
        );
    }
}

TabbedView.propTypes = {
    layout: PropTypes.string,
    tabs: PropTypes.array,
    tabContent: PropTypes.array,
    children: PropTypes.node,
    className: PropTypes.string,
    onTabSelected: PropTypes.func
};

TabbedView.defaultProps = {
    layout: "horizontal",
    tabs: [],
    tabContent: [],
    className: "",
    onTabSelected: (tab) => tab
};
