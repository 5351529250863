/** -------------------------------------------------------------------------------------------------------------------
 * TabContent
 * A component that renders the content view for each tab of the tabbed view component
 *
 * @examples
 *
 *  ```jsx
 *    <TabContent
 *      title="HDR Content"
 *      className="tab-content">
 *          <h3>HDR Video</h3>
 *          <p>Capture awesome videos</p>
 *     </TabContent>
 * ```
 *
 *  @component TabContent
 *  @import TabContent
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


export const TabContent = ({ className, children }) => (
    <div className={classNames("tab-content", className)}>
        {children}
    </div>
);

TabContent.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node
};

TabContent.defaultProps = {
    title: "",
    className: ""
};
