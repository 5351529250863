/** -------------------------------------------------------------------------------------------------------------------
 * RadioButtonGroup
 * A component that renders a list of radio button options and allows for a single selection
 *
 * @examples
 *
 *  ```jsx
 *    <RadioButtonGroup
 *      id="viewports"
 *      options={[
 *          {label: "Small viewport", value: "small"},
 *          {label: "Medium viewport", value: "medium"}
 *      ]}
 *      onChange={(value) => console.log("Viewport changed: ", value)}
 *    />
 * ```
 *
 *  @component RadioButtonGroup
 *  @import RadioButtonGroup
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import getId from "../utils/get-id";
import RadioButton from "./radio-button";

import "./radio-group.scss";

export default class RadioButtonGroup extends PureComponent {

    constructor(props) {
        super(props);

        this.id = props.id || `radio-group-${getId()}`;
        this.state = {
            value: props.defaultValue
        };
    }

    _onChange = (value) => {
        this.setState({
            value
        });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.defaultValue && nextProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                value: nextProps.defaultValue
            });
        }
    }

    render() {
        const { value } = this.state;
        const children = this.props.options.map((option, index) => {
            const optionId = option.id || `option-${getId()}`;

            return (
                <RadioButton
                    key={index}
                    id={optionId}
                    label={option.label}
                    checked={option.value === value}
                    disabled={option.disabled}
                    onChange={() => this._onChange(option.value)}
                />
            );
        });

        return (
            <div className="radio-group-wrapper">
                {children}
            </div>
        );
    }
}


RadioButtonGroup.propTypes = {
    id: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

RadioButtonGroup.defaultProps = {
    options: [],
    onChange: () => {}
};

