/** ------------------------------------------------------------------------------------------------------------------
 * TableHeader
 * A component that renders the header view for a given table
 *
 * @examples
 *
 *  ```jsx
 *    <TableHeader
 *      property="firstName"
 *      columnName="First Name"
 *      sortable={true}
 *      isActiveSortColumn={true}
 *      onClick={() => console.log("on header clicked")}
 *      isAscendingOrder={true}
 *    />
 * ```
 *
 *  @component TableHeader
 *  @import TableHeader
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


export const TableHeader = ({
    property,
    columnName,
    sortable,
    isActiveSortColumn,
    isAscendingOrder,
    onClick
}) => (
    <th
        key={property}
        className={classNames({
            "sorting": sortable,
            "active-sort-column": isActiveSortColumn
        })}
        aria-controls="itemTable"
        onClick={onClick}>
        <label>
            {columnName}
            <span className={classNames("icon", {
                "icon-chevron-down": sortable,
                "icon-chevron-up": isAscendingOrder
            })} />
        </label>
    </th>
);

TableHeader.propTypes = {
    property: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    isActiveSortColumn: PropTypes.bool,
    isAscendingOrder: PropTypes.bool,
    onClick: PropTypes.func
};

