/** ------------------------------------------------------------------------------------------------------------------
 * MailComposer
 * A component that renders the view for composing an email message
 *
 * @examples
 *
 *  ```jsx
 *    <MailComposer
 *      title="Compose New Mail"
 *      body="Hi. Join my university"
 *      onSubmit={(data) => console.log("Sending mail: ", data)}
 *    />
 * ```
 *
 *  @component MailComposer
 *  @import MailComposer
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Events from "@akwaba/events";

import TextArea from "../text-area";
import { Loader } from "../spinner/loader";

import { noop } from "../../../utils/functions";
import { trim, isEmpty } from "../../../utils/strings";
import { defaultValidators } from "../utils/validators";

import "./mail-composer.scss";


export default class MailComposer extends Component {

    constructor(props) {
        super(props);

        this.inputField = React.createRef();

        this.state = {
            recipients: this.props.recipients,
            invalidRecipients: [],
            currentEmail: "",
            subject: "",
            body: this.props.body,
            selectedIndex: null,
            validationStates: {
                recipients: false,
                subject: false
            }
        };
    }

    _renderRecipientsList() {
        const {
            invalidRecipients,
            selectedIndex
        } = this.state;

        return this.state.recipients.map((recipient, index) => (
            <li
                key={recipient}
                data-index={index}
                className={classNames("recipient", {
                    selected: index === selectedIndex,
                    "has-error": invalidRecipients.includes(recipient)
                })}
                onClick={this._onRecipientSelected}>
                {recipient}
            </li>
        ));
    }

    _onEmailChanged = (event) => {
        this.setState({
            currentEmail: event.target.value
        });
    };

    _onRecipientSelected = (event) => {
        this.setState({
            selectedIndex: parseInt(event.target.getAttribute("data-index"), 10)
        });

        if (!this.state.isObservingKeyboardEvents) {
            this._observeKeyboardEvents();
        }
    };

    _observeKeyboardEvents() {
        Events.add(document, "keyup", this._onKeyboardEvent);

        this.setState({
            isObservingKeyboardEvents: true
        });
    }

    _stopObservingKeyboardEvents() {
        Events.remove(document, "keyup", this._onKeyboardEvent);

        this.setState({
            isObservingKeyboardEvents: false
        });
    }

    _onInputFieldFocused = () => {
        if (this.state.isObservingKeyboardEvents) {
            this._stopObservingKeyboardEvents();
        }

        this.setState({
            selectedIndex: null
        });
    };

    _onInputFieldBlurred = (event) => {
        const currentEmail = trim(event.target.value);

        if (!isEmpty(currentEmail)) {
            this._addRecipient(currentEmail);
        }
    };

    _onKeyboardEvent = (event) => {
        const code = event.charCode || event.keyCode;
        const { Keys } = Events;
        let recipients;
        let selectedIndex;

        switch (code) {

            case Keys.KEY_SPACE:
            case Keys.KEY_RETURN:
            case Keys.KEY_TAB:
                const currentEmail = trim(event.target.value);
                ({ recipients } = this.state);

                if (!isEmpty(currentEmail)) {
                    this._addRecipient(currentEmail);
                }

                break;

            case Keys.KEY_RIGHT:
                ({ recipients, selectedIndex } = this.state);

                if (selectedIndex != null && recipients.length > 1) {
                    const nextIndex = (this.state.selectedIndex + 1) % recipients.length;
                    this.setState({
                        selectedIndex: nextIndex
                    });
                }

                break;

            case Keys.KEY_LEFT:
                ({ recipients, selectedIndex } = this.state);

                if (selectedIndex != null && recipients.length > 1) {
                    let prevIndex = this.state.selectedIndex - 1;

                    if (prevIndex < 0) {
                        prevIndex = recipients.length - 1;
                    }

                    this.setState({
                        selectedIndex: prevIndex
                    });
                }

                break;

            case Keys.KEY_DELETE:
            case Keys.KEY_BACKSPACE:
                if (this.state.isObservingKeyboardEvents) {
                    ({ recipients } = this.state);
                    const { selectedIndex } = this.state;
                    recipients.splice(selectedIndex, 1);

                    this.setState({
                        recipients,
                        selectedIndex: null
                    });

                    this._stopObservingKeyboardEvents();
                    this._onRecipientListUpdated();
                }

                break;

            default:
                break;
        }
    };

    _addRecipient(email) {
        const { recipients } = this.state;
        recipients.push(email);

        this.setState({
            recipients,
            currentEmail: ""
        }, () => {
            this._onRecipientListUpdated();
        });
    }

    _onRecipientListUpdated() {
        const {
            recipients: list,
            validationStates
        } = this.state;
        const isValidList = (
            list.length > 0 &&
            list.every((recipient) => defaultValidators.isEmail.test(recipient))
        );
        let invalidRecipients = [];

        if (!isValidList) {
            invalidRecipients = list.filter((recipient) => !defaultValidators.isEmail.test(recipient));
        }

        validationStates.recipients = isValidList;
        this.setState({
            validationStates,
            invalidRecipients
        });
    }

    _onInputChanged = (name, value) => {

        this.setState({
            [name]: value
        });

        if (name === "subject") {
            const { validationStates } = this.state;
            validationStates[name] = !isEmpty(trim(value));

            this.setState({
                validationStates
            });
        }
    };

    _onSubmit = () => {
        const {
            recipients,
            subject,
            body
        } = this.state;
        const data = {
            recipients: recipients.join(","),
            subject,
            body
        };

        if (this.props.onSubmit) {
            this.props.onSubmit(data);
        }
    }

    componentDidMount() {
        if (this.inputField && this.inputField.current) {
            Events.add(this.inputField.current, "keyup", this._onKeyboardEvent);
        }
    }

    componentWillUnmount() {
        if (this.inputField && this.inputField.current) {
            Events.remove(this.inputField.current, "keyup", this._onKeyboardEvent);
        }
    }

    render() {
        const {
            title,
            i18n: { getString },
            showSpinner
        } = this.props;

        const {
            currentEmail,
            subject,
            body,
            validationStates,
            invalidRecipients
        } = this.state;

        const isValidForm = Object.values(validationStates).every((field) => !!field);

        return (
            <div className="mail-composer-wrapper">
                <div className="header">
                    <h3>{title}</h3>
                    <button
                        className="button button-primary"
                        disabled={!isValidForm || showSpinner}
                        onClick={this._onSubmit}
                    >
                        <span>{getString("chat.send")}</span>
                    </button>
                </div>
                <div className="content">
                    <div className="row mail-section">
                        <div className="column label">
                            <label>{getString("mail.to")}:</label>
                        </div>
                        <div className="column">
                            <ul className="recipients">
                                {this._renderRecipientsList()}
                                <li className="input-wrapper">
                                    <input
                                        type="text"
                                        value={currentEmail}
                                        disabled={showSpinner}
                                        onFocus={this._onInputFieldFocused}
                                        onBlur={this._onInputFieldBlurred}
                                        onChange={this._onEmailChanged}
                                        className="input-field email-field"
                                        ref={this.inputField}
                                    />
                                </li>
                            </ul>
                            {(invalidRecipients.length > 0) &&
                                <p className="error-message">
                                    {getString("login.invalidEmail")}
                                </p>
                            }
                        </div>
                    </div>
                    <div className="row mail-section">
                        <div className="column label">
                            <label>{getString("mail.subject")}:</label>
                        </div>
                        <div className="column">
                            <input
                                type="text"
                                value={subject}
                                disabled={showSpinner}
                                onFocus={this._onInputFieldFocused}
                                onChange={(event) => this._onInputChanged("subject", event.target.value)}
                                className="input-field subject-field"
                            />
                        </div>
                    </div>
                    <div className="row mail-section body">
                        <TextArea
                            value={body}
                            placeholder={getString("chat.messageHint")}
                            disabled={showSpinner}
                            onChange={(value) => this._onInputChanged("body", value)}
                        />
                    </div>
                </div>
                {showSpinner && <div className="loader-wrapper">
                    <Loader />
                </div>}
            </div>
        );
    }
}

MailComposer.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    recipients: PropTypes.array,
    className: PropTypes.string,
    i18n: PropTypes.object,
    showSpinner: PropTypes.bool,
    onSubmit: PropTypes.func
};

MailComposer.defaultProps = {
    recipients: [],
    onSubmit: noop,
};
