/** -------------------------------------------------------------------------------------------------------------------
 * NotificationBanner
 * A component that renders different types of notification banners (status, info, success, error, warning)
 *
 * @examples
 *
 *  ```jsx
 *    <NotificationBanner
 *      type="info"
 *      message="This is informational text"
 *      showCloseButton={true}
 *    />
 * ```
 *
 *  @component NotificationBanner
 *  @import NotificationBanner
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./notification-banner.scss";

const icons = {
    info: "infocircle",
    status: "checkcircle",
    success: "checkalt15",
    warning: "exclamationcircle",
    error: "unvalidatedalt19"
};


const NotificationBanner = ({
    message,
    mode,
    type,
    centered,
    showIcon,
    showCloseButton,
    onBannerClose
}) => {
    const classList = classNames("notification-banner", mode, type, {
        "can-close": showCloseButton,
        "centered-text": centered,
        "no-icon": !showIcon
    });

    const iconClassName = classNames(`icon form-icons form-icons-${icons[type]}`, {
        "icon-info": ["info", "status"].includes(type)
    });

    return (
        <div className="notification-wrapper">
            <div className={classList}>
                <div className="row item-content">
                    <p className="icon-wrapper">
                        <span className={iconClassName} />
                    </p>
                    <p className="info">
                        {message}
                    </p>
                </div>
                <button className="icon icon-close modal-close" data-action="close" onClick={onBannerClose} />
            </div>
        </div>
    );
};

NotificationBanner.propTypes = {
    type: PropTypes.oneOf(["info", "status", "error", "success", "warning"]).isRequired,
    message: PropTypes.string,
    mode: PropTypes.string,
    centered: PropTypes.bool,
    showIcon: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    onBannerClose: PropTypes.func
};

NotificationBanner.defaultProps = {
    message: "This is for informational text.",
    mode: "banner",
    type: "info",
    showIcon: true,
    showCloseButton: true,
    onBannerClose: () => {}
};

export default NotificationBanner;
