/** -------------------------------------------------------------------------------------------------------------------
 * SegmentedControl
 * A component that renders a segmented control
 *
 * @examples
 *
 *  ```jsx
 *    <SegmentedControl
 *      segments={[
 *          {id: "month", title: "Month view" },
 *          {id: "list", title: "List view"}
 *      ]}
 *      onSegmentActivated={(segment) => console.log("Segment activated: ", segment)}
 *    />
 * ```
 *
 *  @component SegmentedControl
 *  @import SegmentedControl
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./segmented-control.scss";

export default class SegmentedControl extends Component {

    constructor(props) {
        super(props);

        const currentSegment = props.segments.find((segment) => segment.selected) || props.segments[0];
        this.state = {
            currentSegment
        };
    }

    _onSegmentClick = (event) => {
        event.preventDefault();
        const segment = this.props.segments.find((segment) => segment.id === event.target.id);

        if (segment !== this.state.currentSegment) {
            this._activateSegment(segment);
        }
    }

    _activateSegment(currentSegment) {
        this.setState({
            currentSegment
        });

        if (this.props.onSegmentActivated) {
            this.props.onSegmentActivated(currentSegment);
        }
    }

    render() {
        const {
            segments,
            style,
            size,
            className
        } = this.props;
        const { currentSegment } = this.state;
        const classList = classNames("segmented-control", `segment-${style}`, size, className);

        return (
            <ul className={classList}>
                {segments.map((segment) => {
                    const { title } = segment;
                    const selected = segment === currentSegment;
                    return (
                        <li key={segment.id} className={classNames({ selected })}>
                            <a href="#" key={segment.id} id={segment.id} rel={title}
                                title={title} onClick={this._onSegmentClick}>{title}</a>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

SegmentedControl.propTypes = {
    segments: PropTypes.array,
    style: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    onSegmentActivated: PropTypes.func
};

SegmentedControl.defaultProps = {
    segments: [],
    style: "normal",
    size: "regular",
    className: "",
    onSegmentActivated: () => {}
};
