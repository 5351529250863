/** -------------------------------------------------------------------------------------------------------------------
 * LocalNav
 * A component that renders the local navigation section, with the page info and navigation links if specified
 *
 * @examples
 *
 *  ```jsx
 *    <LocalNav
 *      title="Dashboard"
 *      page="dashboard"
 *      caption="Akwaba Virtual School"
 *      className="section-local-nav"
 *      showPageInfo={true}
 *      showLinks={true}
 *      links={<array_of_links>}
 *      showContextualAction={false}
 *    />
 * ```
 *
 *  @component LocalNav
 *  @import LocalNav
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import getId from "../utils/get-id";

import "./local-nav.scss";


export default class LocalNav extends Component {

    constructor(props) {
        super(props);

        const activeLink = props.links.find((link) => !!link.active) || props.links[0];
        this.state = {
            activeLink
        };
    }

    _onLinkSelected = (event) => {
        event.preventDefault();
        const activeLink = this.props.links.find((link) => link.id === event.currentTarget.id);

        this.setState({
            activeLink
        });

        if (this.props.onLinkSelected) {
            this.props.onLinkSelected(activeLink);
        }
    }

    render() {
        const {
            title,
            caption,
            page,
            subContent,
            className,
            links,
            showPageInfo,
            showLinks,
            showContextualAction,
            contextualActionLabel,
            onContextualAction,
            match
        } = this.props;

        const classList = classNames("localnav-wrapper", className, page);
        const navLinks = links.map((link, index) => {
            const activeClassName = (index === 0) ? (match.isExact ? "current" : "") : "current";

            return (
                <li key={index}>
                    <NavLink
                        id={link.id || `navlink-${getId()}`}
                        to={link.url}
                        activeClassName={activeClassName}>
                        <figure className={classNames("nav-icon", link.icon)} />
                        <span className="nav-label">{link.title}</span>
                    </NavLink>
                </li>
            );
        });

        return (
            <section className={classList}>
                <div className="row">
                    <div className="column small-12 medium-8">
                        <div className={classNames("page-info", {"has-info": showPageInfo})}>
                            <h5>{caption}</h5>
                            <h3>{title}</h3>
                            {subContent && <div className="info-wrapper">{subContent}</div>}
                        </div>
                    </div>
                    <div className="column small-12 medium-4 local-nav-menu-wrapper">
                        {showLinks && <nav className="local-nav">
                            <ul className="local-nav-items">
                                {navLinks}
                            </ul>
                        </nav>}
                        {showContextualAction && <div className="contextual-action">
                            <a href="javascript:void(0)"
                                data-type="context menu"
                                onClick={onContextualAction}>
                                <span className="icon icon-plus-circle-white" />
                                <span>{contextualActionLabel}</span>
                            </a>
                        </div>}
                    </div>
                </div>
            </section>
        );
    }
}

LocalNav.propTypes = {
    title: PropTypes.string,
    caption: PropTypes.string,
    subContent: PropTypes.node,
    page: PropTypes.string,
    className: PropTypes.string,
    links: PropTypes.array,
    showPageInfo: PropTypes.bool,
    showLinks: PropTypes.bool,
    showContextualAction: PropTypes.bool,
    onLinkSelected: PropTypes.func,
    onContextualAction: PropTypes.func,
    contextualActionLabel: PropTypes.string,
    match: PropTypes.object
};

LocalNav.defaultProps = {
    className: "",
    links: [],
    caption: "",
    onLinkSelected: () => {},
    onContextualAction: () => {}
};
