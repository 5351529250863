export default [{
    "displayName": "English",
    "name": "english",
    "id": "us",
    "locale": "en",
    "country": "us"
}, {
    "displayName": "español",
    "name": "spanish",
    "id": "mx",
    "locale": "es",
    "country": "mx"
}, {
    "displayName": "français",
    "name": "french",
    "id": "fr",
    "locale": "fr",
    "country": "fr"
}, {
    "displayName": "italiano",
    "name": "italian",
    "id": "it",
    "locale": "it",
    "country": "it"
}, {
    "displayName": "Deutsch",
    "name": "german",
    "id": "de",
    "locale": "de",
    "country": "de"
}, {
    "displayName": "中文",
    "name": "chinese",
    "id": "cn",
    "locale": "zh",
    "country": "cn"
}, {
    "displayName": "日本語",
    "name": "japanese",
    "id": "jp",
    "locale": "ja",
    "country": "jp"
}, {
    "displayName": "한국어",
    "name": "korean",
    "id": "kr",
    "locale": "ko",
    "country": "kr"
}];
